import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom'
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import MasterTypeApi from '../../../API/MasterTypeApi';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom'

const AdminPermission = () => {

    const [Data, setData] = useState(null);


    useEffect(() => {
        Getdata();
    }, []);

    const { id } = useParams();
    const notify = () => toast.success('Sucessfully Inserted!')
    const errormsg = (Data) => toast.error(Data
        , {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });


    //   get data function
    const Getdata = () => {
        MasterTypeApi.GetstudentDetailsbyrollno(id)
            .then((resp) => {
                if (resp.ok) {
                    let Data = resp.data;
                    setData(Data);

                    console.log("Student  List........===>", Data);
                    // console.log('hi sunny',context.UserDetail.locationId)
                }
            });
    };



    //   post data function
    const postData = (values, resetForm) => {
        MasterTypeApi.Permissioninsert(
            id,
            values.permission,
            values.transactionType
            // console.log("helo",values.permission)
        )

            .then((resp) => {
                // console.log("id",id)

                if (resp.ok) {
                    let Data = resp.data
                    if (Data == "inserted Successfully !") {
                        resetForm();
                        Getdata();
                        notify();

                    }
                    else {
                        resetForm();
                        Getdata();
                        errormsg(Data);

                    }
                }
            })
            .catch((err) => console.log(err));


    };


    return (
        <>
            <div className="card border-warning mb-3">
                <div className="card-header bg-info text-white">New Permission</div>
                <div className="card-title text-danger ml-3 mt-3">All Fields Are Mandatory *</div>
                <div className="card-body ">
                    <Formik
                        initialValues={{

                            permission: '',

                            transactionType: ''

                        }}

                        onSubmit={(values, { resetForm }) => {
                            // same shape as initial values
                            // isPost ? postData(values, resetForm) : putData(values, resetForm);
                            postData(values, resetForm);
                        }}
                    // validationSchema={validationSchema}

                    >
                        {({ errors, touched, values, handleChange, handleBlur }) => (
                            <Form>
                                <div className="row">

                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="permission">Permission</label>
                                                <input type="number"
                                                    className="form-control"
                                                    placeholder="Enter"
                                                    name="permission"
                                                    id="permission"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.permission}
                                                />
                                                {/* {errors.Name && touched.Name ? (
                                                <div style={{ color: "red" }}>{errors.Name}</div>
                                            ) : null} */}
                                            </div>
                                        </div>

                                    </div>

                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label htmlFor="transactionType"> Permission Type </label>
                                            <select
                                                name='transactionType'
                                                id='isactransactionTypetive'
                                                className='form-control input-default'
                                                onBlur={handleBlur}
                                                value={values.transactionType}
                                                onChange={handleChange}
                                            >
                                                <option selected> Select Session</option>
                                                <option value={"cr"}>CR</option>
                                                <option value={"dr"}>DR</option>
                                            </select>
                                            {/* {errors.sessionid && touched.sessionid ? (
                                                <div style={{ color: "red" }}>{errors.sessionid}</div>
                                            ) : null} */}
                                        </div>
                                    </div>



                                </div>



                                {/* <button type="submit" className="btn btn-primary">Submit</button> */}
                                <button type="submit" className="btn btn-primary "> Submit </button>
                            </Form>
                        )}
                    </Formik>
                </div>

            </div>
            <br></br>

            {Data != null ? Data.length > 0 ? (
                <div className="table-responsive">
                    <table className="table table-hover table-outline  mb-0 ">
                        <thead >
                            <tr>
                                <th className="text-center">Sr No</th>
                                <th className="text-center">Total Permission</th>
                                <th className="text-center">Permission Amount</th>
                                <th className="text-center">Trans Type</th>
                                <th className="text-center">Date</th>



                                {/* <th className="text-center">Attendence </th> */}

                            </tr>
                        </thead>
                        <tbody>
                            {Data.map((data, i) => (
                                <tr key={data.id}>
                                    <td className="text-center">{i + 1}</td>
                                    <td className="text-center">{data.balance}</td>
                                    <td className="text-center">{data.transactionAmount}</td>
                                    <td className="text-center">{data.transactionType}</td>
                                    <td className="text-center">{data.createdDate}</td>




                                </tr>
                            ))}

                        </tbody>
                    </table>

                </div>

            ) : <center><h3>School Not Available</h3></center> //<BeatLoader color="blue" loading />
                :

                <div className='d-flex justify-content-center align-items-center'>
                    <button class="btn btn-primary" type="button" disabled>
                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        Loading...
                    </button>
                </div>
            }

        </>

    );
}

export default AdminPermission;
