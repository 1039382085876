import React, { useState, useEffect,useRef, useContext } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import MasterTypeApi from '../../../API/MasterTypeApi';
import AttendenceApi from '../../../API/AttendenceApi';

const Mismatchattendence = () => {
    const notify = () => toast.success('Sucessfully Inserted!')
    const notify2 = () => toast.error(' already Exists!')
    const errormsg = (Data) => toast.error(Data
        , {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

    const [Data, setData] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [MasterTypedata, setMasterTypedata] = useState([]);
    const [Imagefile, setImagefile] = useState('');
    const endDateRef = useRef();







    useEffect(() => {
        Getdata();
    }, []);
    const handleStartDate = (e) => {
        setStartDate(e.target.value);
        endDateRef.current.value = ""

        setEndDate(null);

        console.log('handle change date', e.target.value)
    };

    const handleEndDate = (e) => {
        setEndDate(e.target.value);
    };

    const Getdata = () => {
        AttendenceApi.MismatchAttendence(startDate, endDate)
            .then((resp) => {
                if (resp.ok) {
                    let Data = resp.data;
                    setData(Data);

                 console.log("Student  List........===>", Data);
                    // console.log('hi sunny',context.UserDetail.locationId)
                }
            });
    };

    var current = new Date();
    var dd = current.getDate();
    var mm = current.getMonth() + 1; //January is 0!
    var yyyy = current.getFullYear();

    if (dd < 10) {
        dd = '0' + dd;
    }

    if (mm < 10) {
        mm = '0' + mm;
    }

    current = yyyy + '-' + mm + '-' + dd;

   


    
    function printDiv() {


        //Get the HTML of div
        var divElements = document.getElementById("tblPrintDiv").innerHTML;
        //Get the HTML of whole page
        var oldPage = document.body.innerHTML;
        //Reset the page's HTML with div's HTML only
        document.body.innerHTML =
            `<html><head><title></title></head><body> <h1 style=" text-align: center;">Mismatch Report</h1>` +
            divElements + "</body>";
        //Print Page
        window.print();
        //Restore orignal HTML
        document.body.innerHTML = oldPage;
        window.location.reload(false);
    }




    return (
        <>

            {/* <div className="panel panel-default paper-shadow" data-z="0.5"> */}

            <div className="panel-heading">
                <div className=" form-group daterangepicker-report" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div className=" max-width-300">
                        <i className="fa fa-calendar fa-fw" />
                        <span><strong>Start Date </strong></span>
                        <b className="caret" />
                        <input type="date" className="form-control"
                            //  onChange={handledate}
                            //  selected={startdate}
                            //  onChange={date => setStartDate(date)}
                            required
                            onChange={handleStartDate}
                            selected={startDate}
                            min="2021-04-13"
                            max={current} />
                    </div>
                    <div className=" max-width-300">
                        <i className="fa fa-calendar fa-fw" />
                        <span><strong>End Date </strong></span>
                        <b className="caret" />


                        <input type="date" className="form-control"
                            ref={endDateRef}
                            required
                            min={startDate}
                            selected={endDate != null ? endDate : ""}
                            disabled={startDate == null ? true : false}
                            onChange={handleEndDate}
                            max={current} />
                    </div>

                    <div className=" max-width-300" >
                        <button disabled={endDate == null ? true : false} className="btn btn-success mr-4" style={{ float: 'right', }} onClick={() => Getdata()} >Check Attendence</button>
                    </div>
                    <div className=" max-width-300">
                        <button disabled={Data == null ? true : false} className="btn btn-success mr-4" style={{ float: 'right', }} onClick={() => printDiv()}>Print PDF</button>
                    </div>
                </div>
            </div>


            <div className="panel-heading">
                <div className=" form-group daterangepicker-report" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                    {/* <div className=" max-width-300">
                                    <input type="date" className="form-control" onChange={handledate}
                                        required
                                        min="2022-04-13"
                                        max={current} />
                                </div> */}
                </div>
            </div>

            {Data != null ? Data.length > 0 ? (
                <div id='tblPrintDiv' className="table-responsive">
                    <table className="table table-hover table-outline  mb-0 ">
                        <thead >
                            <tr>
                                <th className="text-center">Sr No</th>
                                <th className="text-center">Date of Attendence</th>
                                <th className="text-center">School Name</th>

                                <th className="text-center">School Attendence </th>
                                <th className="text-center">Diet Attendence </th>
                                <th className="text-center">Diet Name</th>


                                {/* <th className="text-center">Attendence </th> */}

                            </tr>
                        </thead>
                        <tbody>
                            {Data.map((data, i) => (
                                <tr key={data.id}>
                                    <td className="text-center">{i + 1}</td>
                                    <td className="text-center"><strong>{data.date}</strong></td>
                                    <td className="text-center"><strong>{data.schoolname}</strong></td>
                                    <td className="text-center">{data.schoolattendence}</td>
                                    <td className="text-center">{data.dietattendence}</td>
                                    <td className="text-center">{data.diet}</td>

                                    {/* <td className="text-center">   <button className="btn btn-danger  mr-3" style={{ float: 'right', }}
                                        onClick={() => DeltemasterType(data.id)}
                                    >Delete</button>
                                    
                                    <td className="text-center">   <button className="btn btn-success  mr-3" style={{ float: 'right', }}
                                        onClick={() => DeltemasterType(data.id)}
                                    >Update</button></td>
                                    
                                    </td> */}


                                    {/* <td className="text-center">{data.totalStudent}</td> */}






                                </tr>
                            ))}

                        </tbody>
                    </table>

                </div>

            ) : <center><h3>Attendence Not Available</h3></center> //<BeatLoader color="blue" loading />
                :

                <div className='d-flex justify-content-center align-items-center'>
                    <button class="btn btn-primary" type="button" disabled>
                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        Loading...
                    </button>
                </div>
            }






        </>
    );
}

export default Mismatchattendence;
