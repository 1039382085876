import React, { useContext, useEffect, useRef, useState } from 'react'

import { useParams } from 'react-router-dom'
import AttendenceApi from '../../../API/AttendenceApi';
import LoginApi from '../../../API/LoginApi';




function DietAttendncecheck() {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [Dietattendence, setDietAttendence] = useState(null);
    const [Schooldeatil, setSchooldeatil] = useState([]);

    const endDateRef = useRef();

    useEffect(() => {
        GetSchoolAttendencedetail();
        getSchooldeatils();
    }, [])
    const handleStartDate = (e) => {
        setStartDate(e.target.value);
        endDateRef.current.value = ""

        setEndDate(null);

        console.log('handle change date', e.target.value)
    };

    const handleEndDate = (e) => {
        setEndDate(e.target.value);
    };

    const { id } = useParams();

    const DateFormat = (date) => {
        let current = new Date('2022-05-01');
        let dd = current.getDate();
        let mm = current.getMonth() + 1; //January is 0!
        let yyyy = current.getFullYear();

        if (dd < 10) {
            dd = '0' + dd;
        }

        if (mm < 10) {
            mm = '0' + mm;
        }

        current = yyyy + '-' + mm + '-' + dd;

        console.log("formated date...", current)
    }

    var current = new Date();
    var dd = current.getDate();
    var mm = current.getMonth() + 1; //January is 0!
    var yyyy = current.getFullYear();

    if (dd < 10) {
        dd = '0' + dd;
    }

    if (mm < 10) {
        mm = '0' + mm;
    }

    current = yyyy + '-' + mm + '-' + dd;

//   School Name 

const getSchooldeatils = () => {
    LoginApi.UserdetailApi(id).then((resp) => {
      if (resp.ok) {
        let Data = resp.data;
        setSchooldeatil(Data);
        // setSchooldeatil(null);

        console.log('hi sunny',Data)
      }
      else {
        console.log('hi sunny some error')    
    }
    });
  };





    // Get Attendce details
    const GetSchoolAttendencedetail = () => {
        AttendenceApi.GetSchoolAttendence(id, startDate, endDate, 2).then((resp) => {
            if (resp.ok) {
                let Data = resp.data;
                // console.log(context.user)
                if (resp.data != null || resp.data != '') {
                    setDietAttendence(JSON.parse(Data));
                }
                else {
                    setDietAttendence(null);
                }
            }
            else {
                setDietAttendence(null);
            }
        });
    };

    function printDiv() {

        
        //Get the HTML of div
        var divElements = document.getElementById("tblPrintDiv").innerHTML;
        //Get the HTML of whole page
        var oldPage = document.body.innerHTML;
        //Reset the page's HTML with div's HTML only
        document.body.innerHTML = 
          `<html><head><title></title></head><body> <h1 style=" text-align: center;">${Schooldeatil.name}</h1>` + 
          divElements + "</body>";
        //Print Page
        window.print();
        //Restore orignal HTML
        document.body.innerHTML = oldPage;
        window.location.reload(false);
    }

    return (
        <>
        {/* <button onClick={() => printDiv()}>Print</button> */}
            <div>
                <div className="page-section">
                    <h1 className="text-display-1">{Schooldeatil.name}</h1>
                </div>
            </div>
            <div className="panel-heading">
                <div className=" form-group daterangepicker-report" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div className=" max-width-300">
                        <i className="fa fa-calendar fa-fw" />
                        <span><strong>Start Date </strong></span>
                        <b className="caret" />
                        <input type="date" className="form-control"
                            //  onChange={handledate}
                            //  selected={startdate}
                            //  onChange={date => setStartDate(date)}
                            required
                            onChange={handleStartDate}
                            selected={startDate}
                            min="2021-04-13"
                            max={current} />
                    </div>
                    <div className=" max-width-300">
                        <i className="fa fa-calendar fa-fw" />
                        <span><strong>End Date </strong></span>
                        <b className="caret" />


                        <input type="date" className="form-control"
                            ref={endDateRef}
                            required
                            min={startDate}
                            selected={endDate != null ? endDate : ""}
                            disabled={startDate == null ? true : false}
                            onChange={handleEndDate}
                            max={current} />
                    </div>

                    <div className=" max-width-300" >
                    <button disabled={endDate == null ? true : false} className="btn btn-success mr-4" style={{ float: 'right', }} onClick={() => GetSchoolAttendencedetail()} >Check Attendence</button>
                    </div>
                    <div className=" max-width-300">
                    <button disabled={Dietattendence == null ? true : false} className="btn btn-success mr-4" style={{ float: 'right', }}  onClick={() => printDiv()}>Print PDF</button>
                    </div>
                </div>
            </div>

            {/* Detail table start */}

            <div id='tblPrintDiv' className="table-responsive">
                {Dietattendence != null && Dietattendence != '' && Dietattendence.Dates?.length > 0 && (

                    <table className="table table-hover table-outline  mb-0 ">
                        <thead >
                            <tr>
                                <th className="text-center">Name</th>
                                {Dietattendence.Dates.map(item => (
                                    <th className="text-center">{item.Date}</th>
                                ))}
                                <th className="text-center">Attendence Present


                                </th>
                                <th className="text-center">Attendence Absent


                                </th>

                                <th className="text-center">Total Presentage</th>
                            </tr>
                        </thead>
                        <tbody>

                            {Dietattendence.Data?.length > 0 ? Dietattendence.Data.map(item => (

                                <tr >
                                    {/* <td className="text-center">{Data + 1}</td> */}
                                    <td className="text-center">{item.StudentName}</td>
                                    {item.Attendence.length > 0 && item.Attendence.map(data => (
                                        <td className="text-center">{data.Attendence}</td>
                                    ))}
                                    {/* <td className="text-center">{item.Name}</td>
                        <td className="text-center">555</td>
                        <td className="text-center"><strong>{item.Name}</strong></td> */}


                                </tr>
                                )) : (
                                    <center><h3><strong>Data Not Available</strong></h3></center> //<BeatLoader color="blue" loading />
                                )}

                        </tbody>
                    </table>
                )

                }


            </div>

        </>
    )
}

export default DietAttendncecheck