// import { BaseApi } from "./Baseurl";

import BaseApi from './BaseApi';


const   GetDistrictschool= (Locationid) =>
BaseApi.get(`School/GetDistrictSchool/` + Locationid);

const GetDistrict = () =>
  BaseApi.get(`Location/DistrictList`);
  const Block = (uEntityId) =>
  BaseApi.get(`Location/Block/` + uEntityId);

  const LocationSchool = (id) =>
  BaseApi.get("College/UspSchoolListforchange" + `${id}`);

  const Changeschool = (Studentid,Schoolid ) =>
  BaseApi.post(`School/SchoolUpdate?Studentid=${Studentid}&Schoolid=${Schoolid}`);
  // BaseApi.post(`Admin/InsertPermission/${Studentid }`);
// const GetSchoolStudent = (user) =>
//   BaseApi.get(`School/GetSchoolStudent/` + user);

  export default {
    GetDistrictschool,
    GetDistrict,
    Block,
    LocationSchool,
    Changeschool
  }