// import { BaseApi } from "./Baseurl";
import BaseApi from './BaseApi';


const   GetDashboard= (uEntityId) =>
BaseApi.get(`College/CollegeDetail/` + uEntityId);


const GetSchoolStudent = (user,Atttype ) =>
  BaseApi.get(`School/GetSchoolStudent/${user}/${Atttype }`); 

// ----------------School Insert----

const SchoolInsertnew = (blockId,Schooltype ,Code, name,email,password,headname,mobileNo,totalStudent,TotalTeachingStaff,TotalTrainingSeat) =>
BaseApi.post(`School/SchoolInsert?blockId=${blockId}&schooltype=${Schooltype}&Code=${Code}&name=${name}&email=${email}&password=${password}&headname=${headname}&mobileNo=${mobileNo}&totalStudent=${totalStudent}
&TotalTeachingStaff=${TotalTeachingStaff}&TotalTrainingSeat=${TotalTrainingSeat}`);
// ----------------School Update----

  const SchoolUpdate = (Uentityid,blockId,Schooltype ,Code, name,email,password,headname,mobileNo,totalStudent,TotalTeachingStaff,TotalTrainingSeat) =>
BaseApi.post(`School/SchoolUpdatenew?Uentityid=${Uentityid}&blockId=${blockId}&schooltype=${Schooltype}&Code=${Code}&name=${name}&email=${email}&password=${password}&headname=${headname}&mobileNo=${mobileNo}&totalStudent=${totalStudent}
&TotalTeachingStaff=${TotalTeachingStaff}&TotalTrainingSeat=${TotalTrainingSeat}`);
// ----------------School Updatedetails new----

  const SchoolUpdatenew = (Uentityid ,Code,password) =>
BaseApi.post(`School/SchoolUpdateDetailsnew?Uentityid=${Uentityid}&Code=${Code}&password=${password}`);

// ----------------Block Api------------------------
const Block = (uEntityId) =>
  BaseApi.get(`Location/Block/` + uEntityId);
// ----------------Get School Details Api------------------------

  
  const GetSchoolDetail= (Uentityid ) =>
  BaseApi.get(`School/SchoolDetailnew/${Uentityid}`);


  export default {
    GetDashboard,
    GetSchoolStudent,
    SchoolInsertnew,
    Block,
    SchoolUpdate,
    GetSchoolDetail,
    SchoolUpdatenew
  }