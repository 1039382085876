import React, { useContext, useEffect } from "react";
import Dashboard from "../Screens/Dashboard";
import Footer from "./Footer";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { Outlet, useNavigate } from "react-router-dom";
import { Provider } from "../../Context/Appcontext";

const LayoutDiet = (props) => {
  const context = useContext(Provider);
  const navigate = useNavigate();
  useEffect(() => {
    if (context.userType != "District") {
      navigate("/");
    }
  }, []);
  return (
    <div className="st-container">
      <Header />
      <Sidebar />

      <div className="st-pusher" id="content">
        <div className="st-content">
          <div className="st-content-inner padding-none">
            <div
              className="container-fluid"
              style={{ height: window.innerHeight - 109 }}
            >
              <Outlet />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LayoutDiet;
