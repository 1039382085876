import React, { useEffect, useState, useContext } from 'react';
//import { Col, Card, Table, Modal, Button } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import MasterTypeApi from '../../../API/MasterTypeApi';
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { Provider } from '../../../Context/Appcontext';

const validationSchema = Yup.object().shape({


    Q1A1: Yup.string().required("Required!"),
    // Q2A1: Yup.string().required("Required!"),
    // Q2A2: Yup.string().required("Required!"),
    // Q3A1: Yup.string().required("Required!"),
    // Q3A2: Yup.string().required("Required!"),
    // Q3A3: Yup.string().required("Required!"),
    // Q3A4: Yup.string().required("Required!"),
    // Q4A1: Yup.string().required("Required!"),
    // Q4A2: Yup.string().required("Required!"),
    // Q5A1: Yup.string().required("Required!"),
    // Q6A1: Yup.string().required("Required!"),
    // Q7A1: Yup.string().required("Required!"),
    // Q7A2: Yup.string().required("Required!"),
    // Q8A1: Yup.string().required("Required!"),
    // Q8A2: Yup.string().required("Required!"),
    // Q9A1: Yup.string().required("Required!"),
    // Q10A1: Yup.string().required("Required!"),

});

function StudentForm({ ...props }) {

    const context = useContext(Provider);

    const { uentityId } = useParams();

    const notify = (Data) => toast.success(Data, { autoClose: 200 })
    const errormsg = (Data) => toast.error(Data, { autoClose: 200 })

    const [isPost, setIsPost] = useState(true);
    const [editData, setEditData] = useState('');
    const [StudentList, setStudentList] = useState([]);
    const [Q1A1, setPhysicaldDisability] = useState(false);
    const [Q2A1Phase1, setQ2A1Phase1] = useState(false);
    const [Q2A2Phase2, setQ2A2Phase2] = useState(false);
    const [Q6A1, setQ6A1] = useState(false);
    const [Q7A1, setQ7A1] = useState(false);
    const [Q7A2, setQ7A2] = useState(false);


    useEffect(() => {
        GetStudentList();


    }, []);



    // student details
    const GetStudentList = () => {

        // console.log("Stud userId........", props.userDetail);
        MasterTypeApi.GetstudentDetails(uentityId).then(
            (resp) => {
                if (resp.ok) {
                    let Data = resp.data;
                    //   console.log("Student List rerun........", Data);
                    setStudentList(Data)
                    console.log("data check tom new", resp.data)
                }
            }
        );
    };


    //   post data function
    const PostQuestionFunction = async (questionId, mark, Answer1, Answer2 = '', Answer3 = '', Answer4 = '') => {
        await MasterTypeApi.Insertanswer(uentityId, context.user, questionId, mark, Answer1, Answer2, Answer3, Answer4)
            .then((resp) => {
                if (resp.ok) {
                    let Data = resp.data
                    if (Data == "inserted Successfully !") {
                        return notify(Data);
                    }
                    else {
                        notify(Data);
                    }
                }
                if (resp.status = 409) {
                    // errormsg( resp.data)
                }
            })
            .catch((err) => console.log(err));
    }


    //   post data function
    const postData = async (values, resetForm, setSubmitting) => {
        await PostQuestionFunction(1, values.Q1A1, values.Q1A1)
        await PostQuestionFunction(2, ((Q2A1Phase1 != "" ? 0.5 : 0) + (Q2A2Phase2 != "" ? 0.5 : 0)), values.Q2A1, values.Q2A2)
        await PostQuestionFunction(3, ((values.Q3A1 != "" ? 0.25 : 0) + (values.Q3A2 != "" ? 0.25 : 0) + (values.Q3A3 != "" ? 0.25 : 0) + (values.Q3A4 != "" ? 0.25 : 0)), values.Q3A1, values.Q3A2, values.Q3A3, values.Q3A4)
        await PostQuestionFunction(4, ((values.Q4A1 != "" ? 0.5 : 0) + (values.Q4A2 != "" ? 0.5 : 0)), values.Q4A1, values.Q4A2)
        await PostQuestionFunction(5, values.Q5A1 != "" ? 1 : 0, values.Q5A1)
        await PostQuestionFunction(6, values.Q6A1 != "" ? 1 : 0, values.Q6A1)
        await PostQuestionFunction(7, ((values.Q7A1 == 1 ? 0.5 : 0) + (values.Q7A2 == 2 ? 0.5 : 0)), values.Q7A1, values.Q7A2)
        await PostQuestionFunction(8, ((values.Q8A1 != "" ? 0.5 : 0) + (values.Q8A2 != "" ? 0.5 : 0)), values.Q8A1, values.Q8A2)
        await PostQuestionFunction(9, values.Q9A1 != "" ? 1 : 0, values.Q9A1)
        await PostQuestionFunction(10, values.Q10A1 != "" ? 1 : 0, values.Q10A1)

        setSubmitting(false)
    };

    useEffect(() => {
        // console.log("UseddrData",props.data)
        // getDataHandler();
        // getapprovalType();
    }, []);

    // if (!Data) {
    //   return <div>Loading..</div>;
    // }


    return (
        <>
            <div className="card border-warning mb-3">
                <div className="card-header bg-info text-Black">विद्यालय अनुभव कार्यक्रम</div>
                <div className="card-title text-danger ml-3 mt-3">All Fields Are Mandatory * </div>
                {/* <div className="card-title text-info ml-3 mt-3"><h4>Student Name = {StudentList.studentName}</h4> <h4> Aaddhar Number = {StudentList.adharNo}</h4></div> */}
                <strong><h4>Student Name = {StudentList.studentName}</h4></strong>
                <strong><h4>Father Name = {StudentList.fatherName}</h4></strong>
                <h4>Aadhar Number = {StudentList.adharNo}</h4>
                <h4>Mobile Number = {StudentList.mobileNo}</h4>
                <h4>Alloated School = {StudentList.alloatedSchool}</h4>
                <br></br>
                <div className="card-body ">
                    <Formik
                        initialValues={{
                            Q1A1: "",
                            Q2A1: "",
                            Q2A2: "",
                            Q3A1: "",
                            Q3A2: "",
                            Q3A3: "",
                            Q3A4: "",
                            Q4A1: "",
                            Q4A2: "",
                            Q5A1: "",
                            Q6A1: "",
                            Q7A1: "",
                            Q7A2: "",
                            Q8A1: "",
                            Q8A2: "",
                            Q9A1: "",
                            Q10A1: "",

                        }}

                        onSubmit={(values, { resetForm, setSubmitting }) => {
                            // same shape as initial values
                            // isPost ? postData(values, resetForm) : putData(values, resetForm);
                            postData(values, resetForm, setSubmitting);
                        }}
                        validationSchema={validationSchema}

                    >
                        {({ errors, touched, values, handleChange, handleBlur, isSubmitting }) => (
                            <Form>
                                <div className="row">
                                    {/* --------Question 1------------- */}


                                    <div className="col-md-8">
                                        <div className="form-group">
                                            <label htmlFor="Answer1">Question(1)= क्या आपने विधालय अनुभव कार्यक्रम (SIP) में भाग लिया ?</label>



                                            <select
                                                name="Q1A1"
                                                id="Q1A1"
                                                // onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.Q1A1}
                                                className="form-control mb-2"
                                                aria-label="Default select example"
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    e.target.value == 0
                                                        || e.target.value == "null"
                                                        ? setPhysicaldDisability(false)
                                                        : setPhysicaldDisability(true);
                                                }}
                                            >
                                                <option className="form-control mb-2" selected>
                                                    आगे बढ़ने के लिए चयन करें ?
                                                </option>
                                                <option className="form-control mb-2" value={1}>

                                                    हा
                                                </option>
                                                <option className="form-control" value={0}>
                                                    ना
                                                </option>
                                            </select>
                                            {errors.Q1A1 && touched.Q1A1 ? (
                                                <div style={{ color: "red" }}>{errors.Q1A1}</div>
                                            ) : null}
                                        </div>

                                        {Q1A1 ? (
                                            <>





                                                <div className="row">

                                                    {/* --------Question 2------------- */}
                                                    <br></br>
                                                    <div className="col-md-8">
                                                        <div className="form-group">
                                                            <label htmlFor="Answer21" 
                                                            >Question(2)= यदि हाँ तो जिस विधालय में अनुभव कार्यक्रम (SIP) किया उसका नाम लिखें ?</label>
                                                            <div className="row">
                                                                <label htmlFor="Answer21">---- विधालय का नाम (Phase-1) <p>Alloated School = {StudentList.alloatedSchool}</p></label>

                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <input type="text"
                                                                            className="form-control"
                                                                            placeholder="विधालय का नाम"
                                                                            name="Q2A1"
                                                                            id="Q2A1"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.Q2A1}
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <select
                                                                            // name="Q2A1"
                                                                            // id="Q2A1"
                                                                            // onChange={handleChange}
                                                                            // onBlur={handleBlur}
                                                                            // value={values.Q1A1}
                                                                            className="form-control mb-2"
                                                                            aria-label="Default select example"
                                                                            onChange={(e) => {
                                                                                handleChange(e);
                                                                                e.target.value == 0
                                                                                    || e.target.value == ""
                                                                                    ? setQ2A1Phase1(false)
                                                                                    : setQ2A1Phase1(true);
                                                                            }}
                                                                        >

                                                                            <option className="form-control mb-2" value={1}>

                                                                                हा
                                                                            </option>
                                                                            <option className="form-control" value={0}>
                                                                                ना
                                                                            </option>
                                                                        </select>

                                                                    </div>

                                                                </div>

                                                                {errors.Q2A1 && touched.Q2A1 ? (
                                                                    <div style={{ color: "red" }}>{errors.Q2A1}</div>
                                                                ) : null}
                                                            </div>
                                                            <div className="row">
                                                                <label htmlFor="Answer2">---- विधालय का नाम (Phase-2) <p>Alloated School = {StudentList.alloatedSchool}</p></label>

                                                                <div className="col-md-6">
                                                                    <input type="text"
                                                                        className="form-control"
                                                                        placeholder="विधालय का नाम"
                                                                        name="Q2A2"
                                                                        id="Q2A2"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.Q2A2}
                                                                    />
                                                                </div>

                                                                <div className="col-md-6">
                                                                        <select
                                                                            // name="Q2A1"
                                                                            // id="Q2A1"
                                                                            // onChange={handleChange}
                                                                            // onBlur={handleBlur}
                                                                            // value={values.Q1A1}
                                                                            className="form-control mb-2"
                                                                            aria-label="Default select example"
                                                                            onChange={(e) => {
                                                                                handleChange(e);
                                                                                e.target.value == 0
                                                                                    || e.target.value == ""
                                                                                    ? setQ2A2Phase2(false)
                                                                                    : setQ2A2Phase2(true);
                                                                            }}
                                                                        >

                                                                            <option className="form-control mb-2" value={1}>

                                                                                हा
                                                                            </option>
                                                                            <option className="form-control" value={0}>
                                                                                ना
                                                                            </option>
                                                                        </select>

                                                                    </div>

                                                                {errors.Q2A2 && touched.Q2A2 ? (
                                                                    <div style={{ color: "red" }}>{errors.Q2A2}</div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* --------Question 3------------- */}

                                                    <div className="col-md-8">
                                                        <div className="form-group">
                                                            <label htmlFor="Q3">Question(3)= विद्यालय में  विद्यालय अनुभव कार्यक्रम (SIP) कब से कब तक चला ?</label>
                                                            <div className="row">
                                                                <label htmlFor="code"> ----Phase-1----</label>

                                                                <div className="col-md-4">
                                                                    <input type="date"
                                                                        className="form-control"
                                                                        placeholder="Start Date "
                                                                        name="Q3A1"
                                                                        id="Q3A1"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.Q3A1}
                                                                    />
                                                                    {errors.Q3A1 && touched.Q3A1 ? (
                                                                        <div style={{ color: "red" }}>{errors.Q3A1}</div>
                                                                    ) : null}
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <input type="date"
                                                                        className="form-control"
                                                                        placeholder="End Date  "
                                                                        name="Q3A2"
                                                                        id="Q3A2"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.Q3A2}
                                                                    />
                                                                    {errors.Q3A2 && touched.Q3A2 ? (
                                                                        <div style={{ color: "red" }}>{errors.Q3A2}</div>
                                                                    ) : null}
                                                                </div>

                                                            </div>
                                                            <div className="row">
                                                                <label htmlFor="Q3">----Phase-2----</label>

                                                                <div className="col-md-4">
                                                                    <input type="date"
                                                                        className="form-control"
                                                                        placeholder="Start Date "
                                                                        name="Q3A3"
                                                                        id="Q3A3"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.Q3A3}
                                                                    />
                                                                    {errors.Q3A3 && touched.Q3A3 ? (
                                                                        <div style={{ color: "red" }}>{errors.Q3A3}</div>
                                                                    ) : null}
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <input type="date"
                                                                        className="form-control"
                                                                        placeholder="End Date  "
                                                                        name="Q3A4"
                                                                        id="Q3A4"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.Q3A4}
                                                                    />
                                                                    {errors.Q3A4 && touched.Q3A4 ? (
                                                                        <div style={{ color: "red" }}>{errors.Q3A4}</div>
                                                                    ) : null}
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* --------Question 4------------- */}

                                                    <div className="col-md-8">
                                                        <div className="form-group">
                                                            <label htmlFor="Q4A2">Question(4)= विद्यालय में आने व जाने का समय क्या था ? ?</label>
                                                            <div className="row">
                                                                <label htmlFor="code"> ----Time----</label>

                                                                <div className="col-md-4">
                                                                    <input type="text"
                                                                        className="form-control"
                                                                        placeholder="Time of Arrival"
                                                                        name="Q4A1"
                                                                        id="Q4A1"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.Q4A1}
                                                                    />
                                                                    {errors.Q4A1 && touched.Q4A1 ? (
                                                                        <div style={{ color: "red" }}>{errors.Q4A1}</div>
                                                                    ) : null}
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <input type="text"
                                                                        className="form-control"
                                                                        placeholder="Time of Go"
                                                                        name="Q4A2"
                                                                        id="Q4A2"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.Q4A2}
                                                                    />
                                                                    {errors.Q4A2 && touched.Q4A2 ? (
                                                                        <div style={{ color: "red" }}>{errors.Q4A2}</div>
                                                                    ) : null}
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>

                                                    {/* --------Question 5------------- */}

                                                    <div className="col-md-8">
                                                        <div className="form-group">
                                                            <label htmlFor="Q5A1">Question(5)= आपने जिस  विद्यालय में (SIP) पूर्ण की वाह कितने छात्र अध्यापक थे ?( सभी कॉलेज को मिला कर )</label>
                                                            <input type="text"
                                                                className="form-control"
                                                                placeholder="Enter Answer 5"
                                                                name="Q5A1"
                                                                id="Q5A1"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.Q5A1}
                                                            />
                                                            {errors.Q5A1 && touched.Q5A1 ? (
                                                                <div style={{ color: "red" }}>{errors.Q5A1}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    {/* --------Question 6------------- */}

                                                    <div className="col-md-8">
                                                        <div className="form-group">
                                                            <label htmlFor="Q6A1">Question(6)= . क्या आप इस   कार्यक्रम के दौरान शिक्षण कार्य किया ?</label>



                                                            <select
                                                                name="Q6A1"
                                                                id="Q6A1"
                                                                // onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.Q6A1}
                                                                className="form-control mb-2"
                                                                aria-label="Default select example"
                                                                onChange={(e) => {
                                                                    handleChange(e);
                                                                    e.target.value == 0
                                                                        || e.target.value == "null"
                                                                        ? setQ6A1(false)
                                                                        : setQ6A1(true);
                                                                }}

                                                            >
                                                                <option className="form-control mb-2" selected>
                                                                    चयन करें ?
                                                                </option>
                                                                <option className="form-control mb-2" value={1}>

                                                                    हा
                                                                </option>
                                                                <option className="form-control" value={0}>
                                                                    ना
                                                                </option>
                                                            </select>
                                                            {errors.Q6A1 && touched.Q6A1 ? (
                                                                <div style={{ color: "red" }}>{errors.Q6A1}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    {/* --------Question 7------------- */}

                                                    <div className="col-md-8">
                                                        <div className="form-group">
                                                            <label htmlFor="Q7A1">Question(7)= आपने किन-किन कक्षाओं को पढ़ाया ?</label>
                                                            <div className="row">

                                                                <div className="col-md-4">
                                                                    <label htmlFor="Q7A1"> ----Phase 1----</label>


                                                                    <select
                                                                        name="Q7A1"
                                                                        id="Q7A1"
                                                                        // onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.Q7A1}
                                                                        className="form-control mb-2"
                                                                        aria-label="Default select example"
                                                                        onChange={(e) => {
                                                                            handleChange(e);
                                                                            e.target.value == 0
                                                                                || e.target.value == "null"
                                                                                ? setQ7A1(false)
                                                                                : setQ7A1(true);
                                                                        }}

                                                                    >
                                                                        <option className="form-control mb-2" selected>
                                                                            Select
                                                                        </option>
                                                                        <option className="form-control mb-2" value={1}>

                                                                            1 to 5th (Primary)
                                                                        </option>
                                                                        <option className="form-control" value={2}>
                                                                            5th to 8th (Middle)
                                                                        </option>
                                                                    </select>
                                                                    {errors.Q7A1 && touched.Q7A1 ? (
                                                                        <div style={{ color: "red" }}>{errors.Q7A1}</div>
                                                                    ) : null}
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label htmlFor="Q7A2"> ----Phase 2----</label>

                                                                    <select
                                                                        name="Q7A2"
                                                                        id="Q7A2"
                                                                        // onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.Q7A2}
                                                                        className="form-control mb-2"
                                                                        aria-label="Default select example"
                                                                        onChange={(e) => {
                                                                            handleChange(e);
                                                                            e.target.value == 0
                                                                                || e.target.value == "null"
                                                                                ? setQ7A2(false)
                                                                                : setQ7A2(true);
                                                                        }}

                                                                    >
                                                                        <option className="form-control mb-2" selected>
                                                                            Select
                                                                        </option>
                                                                        <option className="form-control mb-2" value={1}>

                                                                            1 to 5th (Primary)
                                                                        </option>
                                                                        <option className="form-control" value={2}>
                                                                            5th to 8th (Middle)
                                                                        </option>
                                                                    </select>
                                                                    {errors.Q7A2 && touched.Q7A2 ? (
                                                                        <div style={{ color: "red" }}>{errors.Q7A2}</div>
                                                                    ) : null}
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                    {/* --------Question 8------------- */}
                                                    <br></br>
                                                    <div className="col-md-8">
                                                        <div className="form-group">
                                                            <label htmlFor="Q8A1">Question(8)= आपने Phase-1 और  Phase-2 जिस  विद्यालय से की है उस  विद्यालय के Headmaster/Principal का नाम लिखें।  ?</label>
                                                            <div className="row">
                                                                <label htmlFor="code">---- HeadMaster/Principal (Phase-1)</label>

                                                                <div className="col-md-8">
                                                                    <input type="text"
                                                                        className="form-control"
                                                                        placeholder="Enter answer 8"
                                                                        name="Q8A1"
                                                                        id="Q8A1"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.Q8A1}
                                                                    />
                                                                </div>

                                                                {errors.Q8A1 && touched.Q8A1 ? (
                                                                    <div style={{ color: "red" }}>{errors.Q8A1}</div>
                                                                ) : null}
                                                            </div>
                                                            <div className="row">
                                                                <label htmlFor="Q8A2">---- HeadMaster/Principal (Phase-2)</label>

                                                                <div className="col-md-8">
                                                                    <input type="text"
                                                                        className="form-control"
                                                                        placeholder="Enter Answer 8"
                                                                        name="Q8A2"
                                                                        id="Q8A2"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.Q8A2}
                                                                    />
                                                                </div>

                                                                {errors.Q8A2 && touched.Q8A2 ? (
                                                                    <div style={{ color: "red" }}>{errors.Q8A2}</div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* --------Question 9------------- */}

                                                    <div className="col-md-8">
                                                        <div className="form-group">
                                                            <label htmlFor="Q9A1">Question(9)= . आपने विद्यालय में कितने पीरियड अध्यापन कार्य किया ? ?</label>
                                                            <input type="text"
                                                                className="form-control"
                                                                placeholder="Enter Answer 9 "
                                                                name="Q9A1"
                                                                id="Q9A1"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.Q9A1}
                                                            />
                                                            {errors.Q9A1 && touched.Q9A1 ? (
                                                                <div style={{ color: "red" }}>{errors.Q9A1}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    {/* --------Question 10------------- */}

                                                    <div className="col-md-8">
                                                        <div className="form-group">
                                                            <label htmlFor="Q10A1">Question(10)= आपने  विद्यालय  अनुभव कार्यक्रम (SIP) के दौरान शिक्षण के अलावा किन-किन गतिविधियों में भाग लिया ? किन्ही तीन गतिविधियों के नाम लिखें। ?</label>
                                                            <input type="text"
                                                                className="form-control"
                                                                placeholder="Enter Answer 10 "
                                                                name="Q10A1"
                                                                id="Q10A1"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.Q10A1}
                                                            />
                                                            {errors.Q10A1 && touched.Q10A1 ? (
                                                                <div style={{ color: "red" }}>{errors.Q10A1}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>

                                            </>
                                        ) : null}

                                    </div>

                                </div>



                                {/* <button type="submit" className="btn btn-primary">Submit</button> */}
                                <button disabled={isSubmitting} type="submit" className="btn btn-primary "> Submit </button>
                            </Form>
                        )}
                    </Formik>

                    <br></br>
                    <br></br>
                </div>

            </div>

        </>

    )
}

// const AppMapStateToProps = (state) => {
//   return {
//     userDetail: state.authentication.currentUser,
//     userRollNumber: state.authentication.rollNumber,

//   };
// };

// const AppMapDispatchToProps = (dispatch) => {
//   return {};
// };

export default StudentForm;
