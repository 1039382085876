import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import DistrictschoolApi from '../../../API/DistrictschoolApi';
import CheckDietAttendnce from './CheckDietAttendnce';

const AdminSchool = () => {
    const { id } = useParams();
    const [SchoolList, setSchooldata] = useState(null);


    useEffect(() => {
        handleSchooldata();

    }, []);

    const handleSchooldata = () => {
        DistrictschoolApi.GetDistrictschool(id).then((resp) => {
            if (resp.ok) {
                let Data = resp.data;
                setSchooldata(Data);

                // console.log("Student  List........===>", Data);
                // console.log('hi sunny',context.UserDetail.locationId)
            }
        });
    };


    return (

        <>
            <div className="page-section">
                <h1 className="text-display-1">District School List</h1>
            </div>

            {SchoolList != null ? SchoolList.length > 0 ? (
                <div className="panel panel-default paper-shadow" data-z="0.5">
                    <div className="panel-heading">
                        <div className=" form-group daterangepicker-report" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div className="form-control max-width-300">
                                <i className="fa fa-calendar fa-fw" />
                                <span><strong>School List </strong></span>
                                <b className="caret" />
                            </div>
                            {/* <div className=" max-width-300">
                               <input type="date" className="form-control" onChange={handledate}
                                   required
                                   min="2022-04-13"
                                   max={current} />
                           </div> */}
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-hover table-outline  mb-0 ">
                            <thead >
                                <tr>
                                    <th className="text-center">SR No</th>
                                    <th className="text-center">Code</th>
                                    <th className="text-center">School Name</th>
                                    <th className="text-center">Address</th>
                                    <th className="text-center">Total Student</th>
                                    <th className="text-center">Check Diet Attendence</th>
                                    <th className="text-center">Check School Attendence </th>

                                </tr>
                            </thead>
                            <tbody>
                                {SchoolList.map((data, i) => (
                                    <tr key={data.uentityId}>
                                        <td className="text-center">{i + 1}</td>
                                        <td className="text-center">{data.code}</td>
                                        <td className="text-center"><strong>{data.schoolName}</strong></td>
                                        {/* <td className="text-center"><strong>{data.mobileNo}</strong></td> */}
                                        <td className="text-center">{data.locationName}</td>
                                        <td className="text-center">{data.totalStudent}</td>
                                        <td className="text-center">
                                            <Link to={`/admin/CheckDietAttendnce/${data.uentityId}/2`}  >   <button type="button" className="btn btn-primary">Diet Attendance</button></Link>
                                        </td>
                                        <td className="text-center">
                                            <Link to={`/admin/CheckDietAttendnce/${data.uentityId}/1`}>  <button type="button" className="btn btn-success">School Attendance</button></Link>
                                        </td>





                                    </tr>
                                ))}

                            </tbody>
                        </table>

                    </div>
                </div>
            ) : <center><h3>School Not Available</h3></center> //<BeatLoader color="blue" loading />
                :

                <div className='d-flex justify-content-center align-items-center'>
                    <button class="btn btn-primary" type="button" disabled>
                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        Loading...
                    </button>
                </div>

            }
        </>


    );
}

export default AdminSchool;
