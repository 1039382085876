// import { BaseApi } from "./Baseurl";
import BaseApi from './BaseApi';


// const GetAttendence = (Schoolid,AttDate,AttType) =>
// BaseApi.get(`Student/GetStudentAtt/` + `${Schoolid}/${AttDate}/${AttType}`);


const StudentAttendencee = (Studentid,Schoolid,Attendence,AttDate,attType) =>
BaseApi.post("Student/StudentAttendence", { Studentid: Studentid,Schoolid:Schoolid,Attendence:Attendence,AttDate:AttDate,attType:attType});

const GetSchoolAttendence = (schoolId ,startDate ,endtDate,attendencetype  ) =>
BaseApi.get(`School/GetSchoolattendence/${schoolId}/${startDate}/${endtDate}/${attendencetype}`);


// Mismatch Attendence
const MismatchAttendence = ( startDate,endtDate) =>
BaseApi.get(`Admin/Mismatchattendence/${startDate}/${endtDate}`);

  export default {
    // GetAttendence,
    StudentAttendencee,
    GetSchoolAttendence,
    MismatchAttendence
  }