import React, { useState, useEffect, useContext } from 'react'
import { Provider } from '../../../Context/Appcontext';
import { toast } from 'react-toastify';
import DistrictschoolApi from '../../../API/DistrictschoolApi';
import { Link } from 'react-router-dom'
import MasterTypeApi from '../../../API/MasterTypeApi';
import { useParams } from 'react-router-dom'
import LoginApi from '../../../API/LoginApi';



const CollageStudentList = () => {
    const context = useContext(Provider);
    const [StudentList, setStudentList] = useState();
    const [Schooldeatil, setSchooldeatil] = useState([]);


    const { id } = useParams();
    const { Studenttype } = useParams();
    const { classid } = useParams();


    useEffect(() => {
        GetStudentList();
        getSchooldeatils();

    }, []);


    const GetStudentList = () => {

        // console.log("Stud userId........", props.userDetail);
        MasterTypeApi.Getrerunstudentlist(id, classid, Studenttype).then(
            (resp) => {
                if (resp.ok) {
                    let Data = resp.data;
                    //   console.log("Student List rerun........", Data);
                    setStudentList(Data)
                    console.log("data nnnnnnnncheck tom", resp.data)
                }
            }
        );
    };

    const getSchooldeatils = () => {
        LoginApi.UserdetailApi(id).then((resp) => {
            if (resp.ok) {
                let Data = resp.data;
                setSchooldeatil(Data);
                // setSchooldeatil(null);

                console.log('hi sunny', Data)
            }
            else {
                console.log('hi sunny some error')
            }
        });
    };



    return (
        <>

            <div className="page-section">

                <h1 className="text-display-1">{Schooldeatil.name}</h1>

            </div>

            {StudentList && StudentList.length > 0 ? (
                <table className="table table-hover table-outline  mb-0 ">
                    <thead >
                        <tr>
                            <th className="text-center">Sr.No</th>
                            <th className="text-center">Code</th>
                            <th className="text-center">Roll No</th>
                            <th className="text-center">Student Name</th>
                            <th className="text-center">Father Name</th>
                            <th className="text-center">Class</th>
                            <th className="text-center">Session</th>
                            <th className="text-center">Alloted School </th>
                            <th className="text-center">IsformFill</th>
                            <th className="text-center">Student Conditions </th>


                        </tr>
                    </thead>

                    <tbody>
                        {StudentList.map((data, i) => (
                            <tr key={data.uentityId}>
                                <td className="text-center">{i + 1}</td>
                                <td className="text-center">{data.uentityId}</td>
                                <td className="text-center">{data.rollNo}</td>
                                <td className="text-center">{data.name}</td>
                                <td className="text-center">{data.fatherName}</td>
                                <td className="text-center">{data.className}</td>
                                <td className="text-center">{data.sessionName}</td>
                                <td className="text-center">{data.alloatedSchool}</td>
                                <td className="text-center">
                                    {data.isformfill
                                        ? 'Form Filled'
                                        : 'Form not Filled'}
                                </td>
                                <td className="text-center">
                                    <button disabled={data.isformfill == 1 ? true : false} type="button" className="btn btn-primary"><Link to={`/Staff/StudentForm/${data.uentityId}`}>Student Form</Link></button>
                                </td>




                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : <center><h3>Student Not Available</h3></center>
            }




        </>


    );
}

export default CollageStudentList;
