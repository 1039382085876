import React, { useState, useEffect, useContext } from 'react';
import MasterTypeApi from '../../../API/MasterTypeApi';

export default function DietDashboard() {

  const [Data, setData] = useState(null);
  useEffect(() => {
    Getdata();
  }, []);
  
  
  
  
  const Getdata = () => {
    MasterTypeApi.GetSchoolNotification(5)
      .then((resp) => {
        if (resp.ok) {
          let Data = resp.data;
          setData(Data);

          console.log("notification  List........===>", Data);
          // console.log('hi sunny',context.UserDetail.locationId)
        }
      });
  };
  return (
    <>
 {/* -----------design Test---------------- */}

 <div className="item col-xs-12 col-lg-12">
        <div className="panel panel-default paper-shadow" data-z="0.5">
          <div className="panel-heading">
            <div className="media v-middle">
              <div className="media-body">
                <h4 className="text-headline margin-none">Notifcation</h4>
                <p className="text-subhead text-light">Latest News</p>
              </div>
              <div className="media-right">
                {/* <a
                  className="btn btn-white btn-flat"
                 
                >
                  Image 
                </a> */}
              </div>
            </div>
          </div>
          <div className="table-responsive">
            {Data != null && Data.length > 0 && Data.map((data, i) => (
              <table className="table text-subhead v-middle">
                <tbody>
                  <tr>
                    <td className="width-100 text-caption">
                      <div className="label label-grey-200 label-xs"><p>{data.name}</p></div>
                    
                    </td>
                    {/* <td className="width-80 text-center">{data.name}</td> */}
                    {/* <td className="width-80 text-center">
                    <a href="#">#9936</a>
                  </td> */}
                    <td className="width-50 "><div className='align-items-center'>
                      <a
                        href={data.image}
                      
                      >
                        {/* Details-- */}
                        <p className="text-subhead text-light">Details <i className="fa fa-download" /> </p>

                       
                    
                      {/* <img
                        src={
                          data.image
                        }
                        className='rounded-lg mr-2'
                        width='80'
                        height='50'
                        alt=''
                      /> */}
                        </a>
                    </div></td>

                  </tr>

                </tbody>
              </table>
            ))}
          </div>
        </div>
      </div>

  
 
    </>
  )
}
