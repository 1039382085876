import React, { useState, useEffect, useContext } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import MasterTypeApi from '../../../API/MasterTypeApi';

const MasterType = () => {
    const notify = () => toast.success('Sucessfully Inserted!')
    const notify2 = () => toast.success('Sucessfully delete!')

    const [Data, setData] = useState(null);
    const [MasterTypedata, setMasterTypedata] = useState([]);
    const [Imagefile, setImagefile] = useState('');
    const handeleimage = (e) => {
        setImagefile(e.target.files[0]);
    }



    useEffect(() => {
        Getdata();
        Getmastertype();
    }, []);
    // mastertype dropdown

    const Getmastertype = () => {
        MasterTypeApi.GetMasterTypedata()
            .then((resp) => {
                if (resp.ok) {
                    let Data = resp.data;
                    setMasterTypedata(Data);

                    // console.log("Student  List........===>", Data);
                    // console.log('hi sunny',context.UserDetail.locationId)
                }
            });
    };

    const Getdata = () => {
        MasterTypeApi.GetMasterTypeDetails()
            .then((resp) => {
                if (resp.ok) {
                    let Data = resp.data;
                    setData(Data);

                    // console.log("Student  List........===>", Data);
                    // console.log('hi sunny',context.UserDetail.locationId)
                }
            });
    };

    const postData = async (values, resetForm) => {
        let form = new FormData();

        form.append('Name', values.name);
        form.append('typeid', values.typeid);
        form.append('Filename', Imagefile);
        form.append('isactive', values.isactive);

        await MasterTypeApi.PostMasterTypeDetails(form)
            .then((resp) => {
                if (resp.ok) {

                    setImagefile('');
                    let Data = resp.data
                    if (Data == "inserted Successfully !"

                    ) {
                        resetForm();
                        setImagefile('');
                        setData(null);
                        Getdata();
                        notify();

                    }
                    else {
                        resetForm();
                        notify();

                    }
                }
            })
            .catch((err) => console.log(err));


    };

    // ----------------------------update data------------------------------------

    // const Updatedata = async (values, resetForm) => {
    //     let form = new FormData();
    //     form.append('id', values.id);

    //     form.append('Name', values.name);
    //     form.append('typeid', values.typeid);
    //     form.append('Filename', Imagefile);
    //     form.append('isactive', values.isactive);

    //     await MasterTypeApi.UpdateMasterTypeDetails(form)
    //         .then((resp) => {
    //             if (resp.ok) {

    //                 setImagefile('');
    //                 let Data = resp.data
    //                 if (Data == "updated Successfully !"

    //                 ) {
    //                     resetForm();
    //                     setImagefile('');
    //                     setData(null);
    //                     Getdata();
    //                     notify();

    //                 }
    //                 else {
    //                     resetForm();
    //                     notify();

    //                 }
    //             }
    //         })
    //         .catch((err) => console.log(err));


    // };


    // mastertype delete
    const DeltemasterType = (SelectedId) => {
        // console.log('sushil',SelectedId)

        MasterTypeApi.Deletemastertype(SelectedId).then((resp) => {
            if (resp.ok) {
                let Data = resp.data;
                if (Data == "Deleted") {
                    notify2();
                    Getdata();


                }
            }
        });
    };




    return (
        <>

            {/* <div className="panel panel-default paper-shadow" data-z="0.5"> */}

            <div className="card border-warning mb-3">
                <div className="card-header bg-info text-white">New Student</div>
                <div className="card-title text-danger ml-3 mt-3">All Fields Are Mandatory *</div>
                <div className="card-body ">
                    <Formik
                        initialValues={{
                            name: '',
                            typeid: '',
                            image: '',
                            isactive: '',

                            // name: isPost ? '' : editData[0].name,
                            // typeid: isPost ? '' : editData[0].typeid,
                            // image: isPost ? '' : editData[0].image,
                            // isactive: isPost ? 1 : editData[0].isactive,






                        }}

                        onSubmit={(values, { resetForm }) => {
                            // same shape as initial values
                            // isPost ? postData(values, resetForm) : putData(values, resetForm);
                            postData(values, resetForm);
                        }}
                    //   validationSchema={validationSchema}

                    >
                        {({ errors, touched, values, handleChange, handleBlur }) => (
                            <Form>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="code">Name</label>
                                            <textarea type="text"
                                                className="form-control"
                                                placeholder="Enter name"
                                                name="name"
                                                id="name"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.name}
                                            />
                                            {errors.code && touched.code ? (
                                                <div style={{ color: "red" }}>{errors.code}</div>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label htmlFor="Gender"> Type Name</label>
                                            <select
                                                name='typeid'
                                                id='typeid'
                                                className='form-control input-default'
                                                onBlur={handleBlur}
                                                value={values.typeid}
                                                onChange={handleChange}
                                            >
                                                <option value={''}> Select Type</option>
                                                {MasterTypedata.map((d) => (
                                                    <option value={d.id}>{d.typeName}</option>
                                                ))}
                                            </select>
                                            {errors.genderid && touched.genderid ? (
                                                <div style={{ color: "red" }}>{errors.genderid}</div>
                                            ) : null}
                                        </div>
                                    </div>


                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label htmlFor="Gender"> Is Active</label>
                                            <select
                                                name='isactive'
                                                id='isactive'
                                                className='form-control input-default'
                                                onBlur={handleBlur}
                                                value={values.isactive}
                                                onChange={handleChange}
                                            >
                                                <option selected> Select Type</option>
                                                <option value={1}>Active</option>
                                                <option value={0}>Deactive</option>

                                            </select>
                                            {errors.genderid && touched.genderid ? (
                                                <div style={{ color: "red" }}>{errors.genderid}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label htmlFor="Gender"> image</label>
                                            <input
                                                type='file'
                                                name='image'
                                                id='image'
                                                onChange={(e) => {
                                                    handeleimage(e);
                                                    handleChange(e);
                                                }}
                                                className='form-control input-default'
                                            />
                                            {errors.genderid && touched.genderid ? (
                                                <div style={{ color: "red" }}>{errors.genderid}</div>
                                            ) : null}
                                        </div>
                                    </div>


                                </div>


                                <div className="row">


                                    {/* <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="Address">Address</label>
                                                <input type="text"
                                                    className="form-control"
                                                    placeholder="Enter Address"
                                                    name="address"
                                                    id="address"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.address}
                                                />
                                                {errors.address && touched.address ? (
                                                    <div style={{ color: "red" }}>{errors.address}</div>
                                                ) : null}
                                            </div>
                                        </div> */}

                                </div>
                                {/* <button type="submit" className="btn btn-primary">Submit</button> */}
                                <button type="submit" className="btn btn-primary "> Submit
                                    {/* {isPost ? "submit": "update".editData} */}


                                </button>



                            </Form>
                        )}
                    </Formik>
                </div>

            </div>


            <div className="panel-heading">
                <div className=" form-group daterangepicker-report" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                    {/* <div className=" max-width-300">
                                    <input type="date" className="form-control" onChange={handledate}
                                        required
                                        min="2022-04-13"
                                        max={current} />
                                </div> */}
                </div>
            </div>

            {Data != null ? Data.length > 0 ? (
                <div className="table-responsive">
                    <table className="table table-hover table-outline  mb-0 ">
                        <thead >
                            <tr>
                                <th className="text-center">Sr No</th>
                                <th className="text-center">Name</th>
                                <th className="text-center">Type Name</th>

                                <th className="text-center">Status </th>
                                <th className="text-center">Image </th>
                                <th className="text-center">Action </th>


                                {/* <th className="text-center">Attendence </th> */}

                            </tr>
                        </thead>
                        <tbody>
                            {Data.map((data, i) => (
                                <tr key={data.id}>
                                    <td className="text-center">{i + 1}</td>
                                    <td className="text-center">{data.name}</td>
                                    <td className="text-center"><strong>{data.typeName}</strong></td>
                                    <td className="text-center">{data.isactive}</td>
                                    <td className="text-center">


                                        <div className='align-items-center'>
                                            {/* {item.logoImage} */}
                                            <img
                                                src={
                                                    data.image
                                                }
                                                className='rounded-lg mr-2'
                                                width='80'
                                                height='50'
                                                alt=''
                                            />
                                        </div></td>
                                    <td className="text-center">   <button className="btn btn-danger  mr-3" style={{ float: 'right', }}
                                        onClick={() => DeltemasterType(data.id)}
                                    >Delete</button>

                                        {/* <td className="text-center">   <button className="btn btn-success  mr-3" style={{ float: 'right', }}
                                        onClick={() => DeltemasterType(data.id)}
                                    >Update</button></td> */}

                                    </td>


                                    {/* <td className="text-center">{data.totalStudent}</td> */}






                                </tr>
                            ))}

                        </tbody>
                    </table>

                </div>

            ) : <center><h3>School Not Available</h3></center> //<BeatLoader color="blue" loading />
                :

                <div className='d-flex justify-content-center align-items-center'>
                    <button class="btn btn-primary" type="button" disabled>
                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        Loading...
                    </button>
                </div>
            }






        </>
    );
}

export default MasterType;
