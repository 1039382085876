import React, { useState, useEffect, useContext } from 'react'
import { Provider } from '../../../Context/Appcontext';
import { toast } from 'react-toastify';
import DistrictschoolApi from '../../../API/DistrictschoolApi';
import { Link } from 'react-router-dom'

export default function Schollist({ ...props }) {
  const context = useContext(Provider);
  const [SchoolList, setSchooldata] = useState(null);


  useEffect(() => {
    handleSchooldata();

  }, []);

  const handleSchooldata = () => {
    DistrictschoolApi.GetDistrictschool(context.UserDetail.locationId).then((resp) => {
      if (resp.ok) {
        let Data = resp.data;
        setSchooldata(Data);

       console.log("Student  List........===>", Data);
        // console.log('hi sunny',context.UserDetail.locationId)
      }
    });
  };


  function printDiv() {

        
    //Get the HTML of div
    var divElements = document.getElementById("tblPrintDiv").innerHTML;
    //Get the HTML of whole page
    var oldPage = document.body.innerHTML;
    //Reset the page's HTML with div's HTML only
    document.body.innerHTML = 
      `<html><head><title></title></head><body> <h1 style=" text-align: center;">School Details</h1>` + 
      divElements + "</body>";
    //Print Page
    window.print();
    //Restore orignal HTML
    document.body.innerHTML = oldPage;
    window.location.reload(false);
}


  return (
    <>
      <div className="page-section">
        <h1 className="text-display-1">District School List</h1>
      </div>

      <div className="panel panel-default paper-shadow" data-z="0.5">
        <div className="panel-heading">
          <div className=" form-group daterangepicker-report" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div className="form-control max-width-300">
              <i className="fa fa-calendar fa-fw" />
              <span><strong>School List </strong></span>
              <b className="caret" />
            </div>
            <div className="form-control max-width-300">
              {/* <i className="fa fa-calendar fa-fw" />
              <span><strong>School List </strong></span> */}
              <button className="btn btn-success mr-4" style={{ float: 'right', }}  onClick={() => printDiv()}>Print PDF</button>

              {/* <b className="caret" /> */}
            </div>
            {/* <div className=" max-width-300">
                                    <input type="date" className="form-control" onChange={handledate}
                                        required
                                        min="2022-04-13"
                                        max={current} />
                                </div> */}
          </div>
        </div>
        {SchoolList != null ? SchoolList.length > 0 ? (
          <div className="table-responsive" id='tblPrintDiv' >
            <table className="table table-hover table-outline  mb-0 ">
              <thead >
                <tr>
                  <th className="text-center">SR No</th>
                  <th className="text-center">Code</th>
                  <th className="text-center">School Name</th>
                  <th className="text-center">Address</th>
                  <th className="text-center">HeadName</th>
                  <th className="text-center">Mobile Number</th>
                  <th className="text-center"> Attendence </th>
                  <th className="text-center">Check Attendence </th>

                </tr>
              </thead>
              <tbody>
             
                {SchoolList.map((data, i) => (
                  <tr key={data.uentityId}>
                    <td className="text-center">{i + 1}</td>
                    <td className="text-center">{data.code}</td>
                    <td className="text-center"><strong>{data.schoolName}</strong></td>
                    {/* <td className="text-center"><strong>{data.mobileNo}</strong></td> */}
                    <td className="text-center">{data.locationName}</td>
                    <td className="text-center">{data.headName}</td>
                    <td className="text-center">{data.mobileNo}</td>
                    <td className="text-center">
                      <Link to={`/diet/SchoolStudents/${data.uentityId}`}>  <button type="button" className="btn btn-primary">Attendance</button></Link>
                    </td>
                    <td className="text-center">
                      <Link to={`/diet/DietAttendncecheck/${data.uentityId}`}>  <button type="button" className="btn btn-success">Check Attendance</button></Link>
                    </td>





                  </tr>
                
                ))}
               

              </tbody>
            </table>

          </div>
        ) : <center><h3>School Not Available</h3></center> //<BeatLoader color="blue" loading />
          :

          <div className='d-flex justify-content-center align-items-center'>
            <button class="btn btn-primary" type="button" disabled>
              <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
              Loading...
            </button>
          </div>

        }
      </div>

    </>
  )
}

