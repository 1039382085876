import React, { useState, useEffect, useContext } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import MasterTypeApi from '../../../API/MasterTypeApi';
import { Provider } from '../../../Context/Appcontext';
import { useParams, useNavigate } from 'react-router-dom'
import SchoolDashboardApi from '../../../API/SchoolDashboardApi';

const validationSchema = Yup.object().shape({


    Code: Yup.string().required("UserName Required!"),

    password: Yup.string().min(5).required("password Required!"),


});



const Schoolpasswordupdate = () => {
    const notify = () => toast.success('Sucessfully Inserted!')
    const notify2 = () => toast.error('Please Check Userename and password!')
    const errormsg = (Data) => toast.error(Data
        , {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });



    const [Schooldata, setSchooldata] = useState(null);

    const [BlockData, setBlockData] = useState([]);
    const [MasterTypedata, setMasterTypedata] = useState([]);
    const [Imagefile, setImagefile] = useState('');
    const handeleimage = (e) => {
        setImagefile(e.target.files[0]);
    }

    const context = useContext(Provider);
    const { id } = useParams();
    const navigate = useNavigate();




    useEffect(() => {
        Blocklist();
        GetSchoolDetails();

        //    console.log('byyyyyyyyy',context.user)
    }, []);
    // mastertype dropdown

    // const Getmastertype = () => {
    //     MasterTypeApi.GetMasterTypedata()
    //         .then((resp) => {
    //             if (resp.ok) {
    //                 let Data = resp.data;
    //                 setMasterTypedata(Data);

    //                 // console.log("Student  List........===>", Data);
    //                 // console.log('hi sunny',context.UserDetail.locationId)
    //             }
    //         });
    // };

    const Blocklist = () => {
        SchoolDashboardApi.Block(context.user)
            .then((resp) => {
                if (resp.ok) {
                    let Data = resp.data;
                    setBlockData(Data);

                    // console.log("Student  List........===>", Data);
                    // console.log('hi sunny',context.UserDetail.locationId)
                }
            });
    };
    const GetSchoolDetails = () => {

        SchoolDashboardApi.GetSchoolDetail(id)
            .then((resp) => {
                if (resp.ok) {
                    let Data = resp.data;
                    setSchooldata(Data[0]);
                    console.log('check sunny', resp.data)

                    // console.log("Student  List........===>", Data);
                    // console.log('hi sunny',context.UserDetail.locationId)
                }
            });
    };



    //   post data function
    const postData = (values, resetForm) => {

        console.log('sunny', id, values)
        SchoolDashboardApi.SchoolUpdatenew(
            id,

            values.Code,

            values.password,


            // console.log("helo",values.permission)
        )

            .then((resp) => {
                // console.log("id",id)

                if (resp.ok) {
                    let Data = resp.data
                    if (Data == "Success") {
                        resetForm();
                        // Getdata();
                        notify();
                        navigate(-1);
                    }
                    else {
                        resetForm();
                        // Getdata();
                        errormsg(Data);


                    }
                }
                else if (resp.status == 409) {

                    notify2();

                }

            })
            .catch((err) => console.log(err));

        // navigate(-1);

    };

    // const postData = async (values, resetForm) => {
    //     let form = new FormData();

    //     form.append('Name', values.name);
    //     form.append('typeid', values.typeid);
    //     form.append('Filename', Imagefile);
    //     form.append('isactive', values.isactive);

    //     await MasterTypeApi.PostMasterTypeDetails(form)
    //         .then((resp) => {
    //             if (resp.ok) {

    //                 setImagefile('');
    //                 let Data = resp.data
    //                 if (Data == "inserted Successfully !"

    //                 ) {
    //                     resetForm();
    //                     setImagefile('');
    //                     setData(null);
    //                     Getdata();
    //                     notify();

    //                 }
    //                 else {
    //                     resetForm();
    //                     notify();

    //                 }
    //             }
    //         })
    //         .catch((err) => console.log(err));


    // };

    // ----------------------------update data------------------------------------

    // const Updatedata = async (values, resetForm) => {
    //     let form = new FormData();
    //     form.append('id', values.id);

    //     form.append('Name', values.name);
    //     form.append('typeid', values.typeid);
    //     form.append('Filename', Imagefile);
    //     form.append('isactive', values.isactive);

    //     await MasterTypeApi.UpdateMasterTypeDetails(form)
    //         .then((resp) => {
    //             if (resp.ok) {

    //                 setImagefile('');
    //                 let Data = resp.data
    //                 if (Data == "updated Successfully !"

    //                 ) {
    //                     resetForm();
    //                     setImagefile('');
    //                     setData(null);
    //                     Getdata();
    //                     notify();

    //                 }
    //                 else {
    //                     resetForm();
    //                     notify();

    //                 }
    //             }
    //         })
    //         .catch((err) => console.log(err));


    // };







    return (
        <>

            {/* <div className="panel panel-default paper-shadow" data-z="0.5"> */}

            <div className="card border-warning mb-3">
                <div className="card-header bg-info text-white">Change Password</div>
                <div className="card-title text-danger ml-3 mt-3">All Fields Are Mandatory *</div>
                {/* <div className="card-title "><h3>School Name = {Schooldata.name}</h3></div> */}
                <div className="card-body ">
                    {Schooldata != null ? (
                        <Formik
                            initialValues={{

                                Code: Schooldata.code,

                                password: Schooldata.password,









                            }}

                            onSubmit={(values, { resetForm }) => {
                                // same shape as initial values
                                // isPost ? postData(values, resetForm) : putData(values, resetForm);
                                postData(values, resetForm);
                            }}
                            validationSchema={validationSchema}

                        >
                            {({ errors, touched, values, handleChange, handleBlur }) => (
                               
                               
                               <Form>
                                                <div className="card-title "><h3>School Name = {Schooldata.name}</h3></div>

                                    <div className="row">
                          
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="Code">UserName</label>
                                                <input type="text"
                                                    className="form-control"
                                                    placeholder="Enter Code"
                                                    name="Code"
                                                    id="Code"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.Code}
                                                />
                                                {errors.Code && touched.Code ? (
                                                    <div style={{ color: "red" }}>{errors.Code}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                       
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="password">School Password</label>
                                                <input type="text"
                                                    className="form-control"
                                                    placeholder="Enter password"
                                                    name="password"
                                                    id="password"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.password}
                                                />
                                                {errors.password && touched.password ? (
                                                    <div style={{ color: "red" }}>{errors.password}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                      








                                    </div>


                                  
                                    {/* <button type="submit" className="btn btn-primary">Submit</button> */}
                                    <button type="submit" className="btn btn-success "> Update
                                        {/* {isPost ? "submit": "update".editData} */}


                                    </button>



                                </Form>
                            )}
                        </Formik>
                    ) : (
                        <div>Loading......... </div>
                    )}

                </div>

            </div>






        </>
    );
}

export default Schoolpasswordupdate;
