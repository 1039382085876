// import { BaseApi } from "./Baseurl";
import BaseApi from './BaseApi';





const GetStudentDetails = (Id  ) =>
  BaseApi.get(`College/StudentDetails/${Id}`); 

  export default {
    GetStudentDetails,
  }