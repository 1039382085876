import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Provider } from '../../Context/Appcontext';

const Sidebar = () => {
    const context = useContext(Provider);
    return (
        <div className="sidebar sidebar-left sidebar-size-3 sidebar-offset-0 sidebar-visible-desktop sidebar-visible-mobile sidebar-skin-dark" id="sidebar-menu" data-type="collapse">
            <div data-scrollable tabIndex={0} style={{ overflowY: "hidden", outline: 'none' }} >

                {/* <div className="sidebar-block">
                    <div className="profile">
                        <a href="#">
                            <img src="images\people\110\guy-6.jpg" alt="people" className="img-circle width-80" />
                        </a>
                        <h4 className="text-display-1 margin-none">Instructor Name</h4>
                    </div>
                </div> */}

                <ul className="sidebar-menu">
                    <li className="active" style={{ margin: (0, 5) }}>
                        <Link to="/Staff">
                      <i className="fa fa-home"></i><span>Dashboard</span>
                        </Link>
                    </li>
                    <li className="active my-5" style={{ margin: (0, 5) }}>
                        <Link className='' to="/Staff/CollageDetail">
                           <i className="fa fa-mortar-board"></i><span>Collage Details</span>
                        </Link>
                    </li>
                    <li className="active my-5" style={{ margin: (0, 5) }}>
                        <Link className='' to="/Staff/StudentListbyRollNo">
                           <i className="fa fa-mortar-board"></i><span>Student By RollNo</span>
                        </Link>
                    </li>
                    {/* <li className="active my-5" style={{ margin: (0, 5) }}>
                        <Link className='' to="/school/StudentList">
                           <i className="fa fa-mortar-board"></i><span>Student Attendnce</span>
                        </Link>
                    </li> */}
                    {/* <li className="active my-5" style={{ margin: (0, 5) }}>
                        <Link className='' to="/school/SchoolStudentAttendnce">
                           <i className="fa fa-mortar-board"></i><span>Student Attendence List</span>
                        </Link>
                    </li> */}
                    <li className="active my-5" style={{ margin: (0, 5) }} onClick={() => context.logoutFunc()}>

                        <a ><i className="fa fa-sign-out"></i><span>Log Out</span></a>

                    </li>

                    {/* <button className='btn btn-primary' onClick={() => context.logoutFunc()}>Logout</button> */}
                </ul>
            </div>
        </div>
    );
};

export default Sidebar;