import React, { useState, useEffect, useContext } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import MasterTypeApi from '../../../API/MasterTypeApi';

const StaffRegistration = () => {
    const notify = () => toast.success('Sucessfully Inserted!')
    const notify2 = () => toast.error(' already Exists!')
    const errormsg = (Data) => toast.error(Data
        , {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

    const [Data, setData] = useState(null);
    const [MasterTypedata, setMasterTypedata] = useState([]);
    const [Imagefile, setImagefile] = useState('');
    const handeleimage = (e) => {
        setImagefile(e.target.files[0]);
    }



    useEffect(() => {
        Getdata();
    }, []);
    // mastertype dropdown

    // const Getmastertype = () => {
    //     MasterTypeApi.GetMasterTypedata()
    //         .then((resp) => {
    //             if (resp.ok) {
    //                 let Data = resp.data;
    //                 setMasterTypedata(Data);

    //                 // console.log("Student  List........===>", Data);
    //                 // console.log('hi sunny',context.UserDetail.locationId)
    //             }
    //         });
    // };

    const Getdata = () => {
        MasterTypeApi.GetStaffDetails()
            .then((resp) => {
                if (resp.ok) {
                    let Data = resp.data;
                    setData(Data);

                    // console.log("Student  List........===>", Data);
                    // console.log('hi sunny',context.UserDetail.locationId)
                }
            });
    };


    //   post data function
    const postData = (values, resetForm) => {
        MasterTypeApi.Staffinsert(

            values.name,
            values.emailId,
            values.mobileNo,
            values.username,
            values.password

            // console.log("helo",values.permission)
        )

            .then((resp) => {
                // console.log("id",id)

                if (resp.ok) {
                    let Data = resp.data
                    if (Data == "inserted Successfully !") {
                        resetForm();
                        Getdata();
                        notify();

                    }
                    else {
                        resetForm();
                        Getdata();
                        notify2();

                    }
                }
            })
            .catch((err) => console.log(err));


    };

    // const postData = async (values, resetForm) => {
    //     let form = new FormData();

    //     form.append('Name', values.name);
    //     form.append('typeid', values.typeid);
    //     form.append('Filename', Imagefile);
    //     form.append('isactive', values.isactive);

    //     await MasterTypeApi.PostMasterTypeDetails(form)
    //         .then((resp) => {
    //             if (resp.ok) {

    //                 setImagefile('');
    //                 let Data = resp.data
    //                 if (Data == "inserted Successfully !"

    //                 ) {
    //                     resetForm();
    //                     setImagefile('');
    //                     setData(null);
    //                     Getdata();
    //                     notify();

    //                 }
    //                 else {
    //                     resetForm();
    //                     notify();

    //                 }
    //             }
    //         })
    //         .catch((err) => console.log(err));


    // };

    // ----------------------------update data------------------------------------

    // const Updatedata = async (values, resetForm) => {
    //     let form = new FormData();
    //     form.append('id', values.id);

    //     form.append('Name', values.name);
    //     form.append('typeid', values.typeid);
    //     form.append('Filename', Imagefile);
    //     form.append('isactive', values.isactive);

    //     await MasterTypeApi.UpdateMasterTypeDetails(form)
    //         .then((resp) => {
    //             if (resp.ok) {

    //                 setImagefile('');
    //                 let Data = resp.data
    //                 if (Data == "updated Successfully !"

    //                 ) {
    //                     resetForm();
    //                     setImagefile('');
    //                     setData(null);
    //                     Getdata();
    //                     notify();

    //                 }
    //                 else {
    //                     resetForm();
    //                     notify();

    //                 }
    //             }
    //         })
    //         .catch((err) => console.log(err));


    // };


    // mastertype delete
    const DeltemasterType = (SelectedId) => {
        // console.log('sushil',SelectedId)

        MasterTypeApi.Deletemastertype(SelectedId).then((resp) => {
            if (resp.ok) {
                let Data = resp.data;
                if (Data == "Deleted") {
                    notify2();
                    Getdata();


                }
            }
        });
    };




    return (
        <>

            {/* <div className="panel panel-default paper-shadow" data-z="0.5"> */}

            <div className="card border-warning mb-3">
                <div className="card-header bg-info text-white">New Staff Register</div>
                <div className="card-title text-danger ml-3 mt-3">All Fields Are Mandatory *</div>
                <div className="card-body ">
                    <Formik
                        initialValues={{
                            name: '',
                            emailId: '',
                            mobileNo: '',
                            username: '',
                            password: ''

                            // name: isPost ? '' : editData[0].name,
                            // typeid: isPost ? '' : editData[0].typeid,
                            // image: isPost ? '' : editData[0].image,
                            // isactive: isPost ? 1 : editData[0].isactive,






                        }}

                        onSubmit={(values, { resetForm }) => {
                            // same shape as initial values
                            // isPost ? postData(values, resetForm) : putData(values, resetForm);
                            postData(values, resetForm);
                        }}
                    //   validationSchema={validationSchema}

                    >
                        {({ errors, touched, values, handleChange, handleBlur }) => (
                            <Form>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="name">Name</label>
                                            <input type="text"
                                                className="form-control"
                                                placeholder="Enter name"
                                                name="name"
                                                id="name"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.name}
                                            />
                                            {errors.code && touched.code ? (
                                                <div style={{ color: "red" }}>{errors.code}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="emailId">Email Id</label>
                                            <input type="text"
                                                className="form-control"
                                                placeholder="Enter emailId"
                                                name="emailId"
                                                id="emailId"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.emailId}
                                            />
                                            {errors.code && touched.code ? (
                                                <div style={{ color: "red" }}>{errors.code}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="mobileNo">Mobile No</label>
                                            <input type="text"
                                                className="form-control"
                                                placeholder="Enter mobileNo"
                                                name="mobileNo"
                                                id="mobileNo"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.mobileNo}
                                            />
                                            {errors.code && touched.code ? (
                                                <div style={{ color: "red" }}>{errors.code}</div>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="username">Username-</label>
                                            <input type="text"
                                                className="form-control"
                                                placeholder="Enter username"
                                                name="username"
                                                id="username"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.username}
                                            />
                                            {errors.code && touched.code ? (
                                                <div style={{ color: "red" }}>{errors.code}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="password">Password-</label>
                                            <input type="text"
                                                className="form-control"
                                                placeholder="Enter password"
                                                name="password"
                                                id="password"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.password}
                                            />
                                            {errors.code && touched.code ? (
                                                <div style={{ color: "red" }}>{errors.code}</div>
                                            ) : null}
                                        </div>
                                    </div>




                                </div>


                                <div className="row">


                                    {/* <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="Address">Address</label>
                                                <input type="text"
                                                    className="form-control"
                                                    placeholder="Enter Address"
                                                    name="address"
                                                    id="address"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.address}
                                                />
                                                {errors.address && touched.address ? (
                                                    <div style={{ color: "red" }}>{errors.address}</div>
                                                ) : null}
                                            </div>
                                        </div> */}

                                </div>
                                {/* <button type="submit" className="btn btn-primary">Submit</button> */}
                                <button type="submit" className="btn btn-primary "> Submit
                                    {/* {isPost ? "submit": "update".editData} */}


                                </button>



                            </Form>
                        )}
                    </Formik>
                </div>

            </div>


            <div className="panel-heading">
                <div className=" form-group daterangepicker-report" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                    {/* <div className=" max-width-300">
                                    <input type="date" className="form-control" onChange={handledate}
                                        required
                                        min="2022-04-13"
                                        max={current} />
                                </div> */}
                </div>
            </div>

            {Data != null ? Data.length > 0 ? (
                <div className="table-responsive">
                    <table className="table table-hover table-outline  mb-0 ">
                        <thead >
                            <tr>
                                <th className="text-center">Sr No</th>
                                <th className="text-center">Name</th>
                                <th className="text-center">Email Id</th>

                                <th className="text-center">Mobile No </th>
                                <th className="text-center">Username </th>
                                <th className="text-center">Password </th>


                                {/* <th className="text-center">Attendence </th> */}

                            </tr>
                        </thead>
                        <tbody>
                            {Data.map((data, i) => (
                                <tr key={data.id}>
                                    <td className="text-center">{i + 1}</td>
                                    <td className="text-center"><strong>{data.name}</strong></td>
                                    <td className="text-center"><strong>{data.emailId}</strong></td>
                                    <td className="text-center">{data.mobileNo}</td>
                                    <td className="text-center">{data.username}</td>
                                    <td className="text-center">{data.password}</td>

                                    {/* <td className="text-center">   <button className="btn btn-danger  mr-3" style={{ float: 'right', }}
                                        onClick={() => DeltemasterType(data.id)}
                                    >Delete</button>
                                    
                                    <td className="text-center">   <button className="btn btn-success  mr-3" style={{ float: 'right', }}
                                        onClick={() => DeltemasterType(data.id)}
                                    >Update</button></td>
                                    
                                    </td> */}


                                    {/* <td className="text-center">{data.totalStudent}</td> */}






                                </tr>
                            ))}

                        </tbody>
                    </table>

                </div>

            ) : <center><h3>Staff Not Available</h3></center> //<BeatLoader color="blue" loading />
                :

                <div className='d-flex justify-content-center align-items-center'>
                    <button class="btn btn-primary" type="button" disabled>
                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        Loading...
                    </button>
                </div>
            }






        </>
    );
}

export default StaffRegistration;
