import React from 'react';
import bhiwani from './../../assets/bhivani.jpg'

const Header = () => {
    return (
        <div className="navbar navbar-size-large navbar-default navbar-fixed-top" role="navigation" >
            <div className="container-fluid">
                <div className="navbar-header">
                    <a href="#sidebar-menu" data-toggle="sidebar-menu" className="toggle pull-left visible-xs"><i className="fa fa-ellipsis-v"></i></a>
                    <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#main-nav">
                        <span className="sr-only">Toggle navigation</span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                    </button>
                    <div className="navbar-brand-logo ">
                    
                            <img
                                src={bhiwani}
                                alt="logo"
                                height="50"
                                width="250"
                            />
                            {/*
                            <svg viewBox="0 0 106 64" height="100%" width="100%" preserveAspectRatio="xMidYMid meet">
                                <path d="M4.731,36.187h8.234v3.414H0.279V20.481h4.452V36.187z"></path>
                                <path d="M21.094,39.878c-2.093,0-3.763-0.658-5.01-1.971c-1.248-1.313-1.872-2.959-1.872-4.938v-0.498
                    c0-2.163,0.567-3.929,1.7-5.3c1.134-1.37,2.756-2.05,4.866-2.042c1.926,0,3.436,0.578,4.531,1.733
                    c1.095,1.156,1.642,2.723,1.642,4.701v2.325h-8.116l-0.026,0.078c0.096,0.727,0.394,1.325,0.893,1.793s1.164,0.703,1.996,0.703
                    c0.84,0,1.513-0.066,2.016-0.198c0.504-0.131,1.141-0.359,1.911-0.683l1.051,2.705c-0.622,0.456-1.425,0.834-2.41,1.136
                    C23.281,39.726,22.223,39.878,21.094,39.878z M20.779,28.544c-0.639,0-1.119,0.228-1.438,0.683c-0.32,0.455-0.514,1.064-0.585,1.825
                    l0.039,0.066h3.822v-0.341c0-0.718-0.149-1.269-0.446-1.655C21.873,28.737,21.409,28.544,20.779,28.544z"></path>
                                <path d="M36.997,39.588c-0.123-0.227-0.234-0.481-0.335-0.761c-0.101-0.281-0.186-0.57-0.256-0.868
                    c-0.42,0.587-0.928,1.053-1.523,1.399c-0.596,0.346-1.318,0.52-2.167,0.52c-1.348,0-2.445-0.391-3.29-1.169
                    c-0.844-0.779-1.267-1.817-1.267-3.113c0-1.409,0.528-2.496,1.582-3.263c1.055-0.766,2.66-1.149,4.813-1.149h1.615v-1.011
                    c0-0.604-0.142-1.068-0.427-1.392c-0.285-0.324-0.725-0.486-1.32-0.486c-0.508,0-0.886,0.12-1.136,0.361
                    c-0.249,0.24-0.374,0.615-0.374,1.123l-4.294-0.013l-0.026-0.079c-0.061-1.296,0.477-2.379,1.615-3.251
                    c1.138-0.871,2.644-1.306,4.518-1.306c1.751,0,3.172,0.438,4.261,1.313c1.091,0.875,1.635,2.127,1.635,3.756v5.358
                    c0,0.762,0.063,1.466,0.191,2.115c0.127,0.647,0.317,1.29,0.571,1.93L36.997,39.588z M34.069,36.712c0.438,0,0.84-0.088,1.208-0.263
                    c0.368-0.175,0.665-0.403,0.893-0.683v-2.22h-1.576c-0.701,0-1.21,0.171-1.53,0.513c-0.32,0.341-0.48,0.775-0.48,1.301
                    c0,0.402,0.138,0.729,0.413,0.978C33.275,36.588,33.631,36.712,34.069,36.712z"></path>
                                <path d="M51.115,29.306l-1.615-0.013c-0.508,0-0.924,0.085-1.248,0.256c-0.324,0.17-0.565,0.413-0.722,0.729
                    v9.324h-4.439V25.392h4.176l0.158,2.088c0.297-0.744,0.691-1.322,1.182-1.733c0.49-0.412,1.064-0.617,1.72-0.617
                    c0.21,0,0.425,0.015,0.643,0.046c0.219,0.031,0.412,0.068,0.578,0.112L51.115,29.306z"></path>
                                <path d="M57.116,25.392l0.171,1.996c0.464-0.709,1.037-1.263,1.72-1.662c0.683-0.398,1.445-0.597,2.285-0.597
                    c1.392,0,2.48,0.451,3.263,1.353c0.784,0.902,1.176,2.338,1.176,4.307v8.812h-4.439v-8.825c0-0.814-0.168-1.39-0.505-1.727
                    c-0.337-0.337-0.834-0.506-1.491-0.506c-0.412,0-0.777,0.074-1.097,0.223c-0.319,0.149-0.593,0.355-0.82,0.617v10.217h-4.426V25.392
                    H57.116z"></path>
                                <path d="M72.796,39.602h-4.452V25.392h4.452V39.602z"></path>
                                <path d="M79.599,25.392l0.171,1.996c0.464-0.709,1.037-1.263,1.72-1.662c0.683-0.398,1.446-0.597,2.286-0.597
                    c1.391,0,2.479,0.451,3.263,1.353c0.783,0.902,1.175,2.338,1.175,4.307v8.812h-4.438v-8.825c0-0.814-0.169-1.39-0.506-1.727
                    c-0.337-0.337-0.835-0.506-1.49-0.506c-0.412,0-0.778,0.074-1.097,0.223c-0.32,0.149-0.593,0.355-0.821,0.617v10.217h-4.425V25.392
                    H79.599z"></path>
                                <path d="M90.038,33.7c0-2.568,0.559-4.638,1.676-6.211c1.116-1.573,2.694-2.359,4.733-2.359
                    c0.872,0,1.639,0.205,2.298,0.617c0.66,0.41,1.228,0.997,1.705,1.758l0.351-2.07h4.506v15.831c0,2.203-0.756,3.968-2.267,5.297
                    c-1.513,1.329-3.547,1.995-6.105,1.995c-0.852,0-2.679-0.351-2.679-0.351s-1.821-0.548-2.663-0.944l0.837-3.805
                    c0.751,0.324,1.467,0.565,2.147,0.723c0.679,0.158,1.456,0.237,2.328,0.237c1.055,0,1.862-0.285,2.421-0.852
                    c0.557-0.569,0.837-1.345,0.837-2.33v-0.837c-0.467,0.598-1.01,1.052-1.628,1.362c-0.619,0.31-1.325,0.465-2.116,0.465
                    c-2.029,0-3.6-0.752-4.711-2.253c-1.112-1.502-1.668-3.486-1.668-5.952V33.7z M95.169,34.019c0,1.34,0.196,2.383,0.586,3.128
                    c0.39,0.747,1.047,1.12,1.971,1.12c0.559,0,1.038-0.087,1.439-0.259c0.401-0.172,0.733-0.437,0.997-0.792v-6.911
                    c-0.254-0.386-0.581-0.685-0.982-0.898c-0.401-0.213-0.875-0.32-1.423-0.32c-0.913,0-1.572,0.421-1.979,1.263
                    c-0.406,0.842-0.609,1.958-0.609,3.349V34.019z"></path>
                                <path d="M89.645,42.239c-3.935-1.997-5.801-1.022-8.698-0.365c-2.523,0.572-11.7,3.366-23.909,3.575
                    c-12.162,0.208-20.212-3.108-20.212-3.108s9.737,6.215,20.023,6.215c7.745,0,10.823-0.699,24.614-2.958
                    c4.857-0.796,10.591,1.859,10.591,1.859l0.757-3.843L89.645,42.239z"></path>
                                <circle cx="70.688" cy="19.042" r="3.167"></circle>
                            </svg>*/}
                  
                    </div>
                </div>

                <div className="collapse navbar-collapse" id="main-nav">
                    <ul className="nav navbar-nav">
                        {/* <li className="dropdown">
                            <a href="#" className="dropdown-toggle" data-toggle="dropdown">Forum <span className="caret"></span></a>
                            <ul className="dropdown-menu">
                                <li><a href="app-forum.html">Forum Home</a></li>
                                <li><a href="app-forum-category.html">Forum Category</a></li>
                            </ul>
                        </li> */}
                        {/* <li className="dropdown">
                            <a href="#" className="dropdown-toggle" data-toggle="dropdown">Courses <span className="caret"></span></a>
                            <ul className="dropdown-menu">
                                <li><a href="app-directory-grid.html">Courses Grid</a></li>
                                <li><a href="app-directory-list.html">Courses List</a></li>
                                <li><a href="app-student-course.html">Course Details</a></li>
                            </ul>
                        </li> */}
                        {/* <li className="dropdown">
                            <a href="#" className="dropdown-toggle" data-toggle="dropdown">Student <span className="caret"></span></a>
                            <ul className="dropdown-menu">
                                <li><a href="app-student-dashboard.html">Dashboard</a></li>
                                <li><a href="app-student-courses.html">My Courses</a></li>
                                <li><a href="app-take-course.html">Take Course</a></li>
                                <li><a href="app-course-forums.html">Course Forums</a></li>
                                <li><a href="app-take-quiz.html">Take Quiz</a></li>
                                <li><a href="app-student-profile.html">Edit Profile</a></li>
                                <li><a href="app-student-billing.html">Edit Billing</a></li>
                                <li><a href="app-student-messages.html">Messages</a></li>
                            </ul>
                        </li> */}
                        {/* <li className="dropdown active">
                            <a href="#" className="dropdown-toggle" data-toggle="dropdown">Instructor <span className="caret"></span></a>
                            <ul className="dropdown-menu">
                                <li className="active"><a href="app-instructor-dashboard.html">Dashboard</a></li>
                                <li><a href="app-instructor-courses.html">My Courses</a></li>
                                <li><a href="app-instructor-course-edit-course.html">Edit Course</a></li>
                                <li><a href="app-instructor-earnings.html">Earnings</a></li>
                                <li><a href="app-instructor-statement.html">Statement</a></li>
                                <li><a href="app-instructor-profile.html">Edit Profile</a></li>
                                <li><a href="app-instructor-billing.html">Edit Billing</a></li>
                                <li><a href="app-instructor-messages.html">Messages</a></li>
                            </ul>
                        </li> */}
                    </ul>
                   
                </div>

            </div>
        </div>
    );
};

export default Header;