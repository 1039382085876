import React, { useState, useEffect, useContext } from 'react'
import { Provider } from '../../../Context/Appcontext';
import { toast } from 'react-toastify';
import DistrictschoolApi from '../../../API/DistrictschoolApi';
import { Link } from 'react-router-dom'
import MasterTypeApi from '../../../API/MasterTypeApi';
import { useParams } from 'react-router-dom'

export default function CollageList({ ...props }) {
    const context = useContext(Provider);
    const [SchoolList, setSchooldata] = useState(null);
    const [updateModal, setupdateModal] = useState(false);

    const { id } = useParams();


    useEffect(() => {
        handleSchooldata();

    }, []);

    const handleSchooldata = () => {
        MasterTypeApi.GetdistrictCollage(id).then((resp) => {
            if (resp.ok) {
                let Data = resp.data;
                setSchooldata(Data);

                // console.log("Student  List........===>", Data);
                // console.log('hi sunny',context.UserDetail.locationId)
            }
        });
    };


    return (
        <>
            <div className="page-section">
                <h1 className="text-display-1">Collage List</h1>
            </div>

            {SchoolList != null ? SchoolList.length > 0 ? (
                <div className="panel panel-default paper-shadow" data-z="0.5">
                    <div className="panel-heading">
                        <div className=" form-group daterangepicker-report" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div className="form-control max-width-300">
                                <i className="fa fa-calendar fa-fw" />
                                <span><strong>Collage List </strong></span>
                                <b className="caret" />
                            </div>
                            {/* <div className=" max-width-300">
                                    <input type="date" className="form-control" onChange={handledate}
                                        required
                                        min="2022-04-13"
                                        max={current} />
                                </div> */}
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-hover table-outline  mb-0 ">
                            <thead >
                                <tr>
                                    <th className="text-center">SR No</th>
                                    <th className="text-center">Collage Name</th>
                                    {/* <th className="text-center">Student Re-Run</th> */}
                                    <th className="text-center">Student JBT-1</th>
                                    <th className="text-center">Student JBT-2</th>

                                </tr>
                            </thead>
                            <tbody>
                                {SchoolList.map((data, i) => (
                                    <tr key={data.id}>
                                        <td className="text-center">{i + 1}</td>
                                        <td className="text-center"><strong>{data.name}</strong></td>
                                        {/* <td className="text-center">
                                            <Link to={`/Staff/CollageStudentList/${data.id}/0/3`}>  <button type="button" className="btn btn-primary">Student Details Re-Run</button></Link>
                                        </td> */}
                                        <td className="text-center">
                                            <Link to={`/Staff/CollageStudentList/${data.id}/2/0`}>  <button type="button" className="btn btn-primary">Student JBT-1</button></Link>
                                        </td>
                                        <td className="text-center">
                                            <Link to={`/Staff/CollageStudentList/${data.id}/1/0`}>  <button type="button" className="btn btn-primary">Student JBT-2</button></Link>
                                        </td>
                                  
                                    
                                        {/* <td className="text-center">
                      <Link to={`/diet/CollageStudent/${data.id}/1`}>  <button type="button" className="btn btn-primary">Student Details Jbt 2</button></Link>
                    </td> */}

                                        {/* <td className="text-center"><strong>{data.mobileNo}</strong></td> */}
                                        {/* <td className="text-center">{data.locationName}</td>
                    <td className="text-center">{data.totalStudent}</td> */}
                                        {/* <td className="text-center">
                      <Link to={`/diet/SchoolStudents/${data.uentityId}`}>  <button type="button" className="btn btn-primary">Attendance</button></Link>
                    </td>
                    <td className="text-center">
                      <Link to={`/diet/DietAttendncecheck/${data.uentityId}`}>  <button type="button" className="btn btn-success">Check Attendance</button></Link>
                    </td> */}





                                    </tr>
                                ))}

                            </tbody>
                        </table>

                    </div>
                </div>
            ) : <center><h3>School Not Available</h3></center> //<BeatLoader color="blue" loading />
                :

                <div className='d-flex justify-content-center align-items-center'>
                    <button class="btn btn-primary" type="button" disabled>
                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        Loading...
                    </button>
                </div>

            }



            {/* modal body Start */}


         
        </>
    )
}

