/* eslint-disable import/no-anonymous-default-export */
import BaseApi from "./BaseApi";
//import jwt_decode from "jwt-decode";
// let token = "Bearer " + localStorage.getItem("token");

// let token = localStorage.getItem("user");
// let decoded = jwt_decode(token);
// let uEntityId=parseInt(decoded.unique_name)
// console.log("decoded",  decoded.unique_name);
//{ headers: {"Authorization" : `Bearer ${token}`} }

 
// JBT

const getdataLpgin = () => BaseApi.get("Home/Login");
const getdataSingle = (uentityId) => BaseApi.get(`College/StudentDetails${uentityId}`);

//const Postdatas=(form)=> Baseurl.post("Home",form);
// const deltedata=(id)=>Baseurl.delete("PageCatgeoryType/"+id);
const LoginJBT = (username, password) =>
  BaseApi.post("Home/Login", { userCode: username, password: password });

const Postdatastudentlist = (uEntityId, classid, studenttype = 0) =>
  BaseApi.get(`College/StudentListGet/` + `${uEntityId}/${classid}?Semester=${studenttype}`);
// Re run student
const GetReRunstudentlist = (uEntityId, StudentType, classId) =>
  BaseApi.get(`College/StudentListGetRerun/` + `${uEntityId}/${classId}/${StudentType}`);



const Pendingdatastudentlist = (uEntityId, classid) =>
  BaseApi.get(`College/StudentPendingListGet/` + `${uEntityId}/${classid}`);
const Dashboard = (uEntityId) =>
  BaseApi.get(`College/CollegeDetail/` + uEntityId);
const Block = (uEntityId) =>
  BaseApi.get(`Location/Block/` + uEntityId);
const Totaldata = (CollegeId) =>
  BaseApi.get(`College/TotaldataGet/` + CollegeId);

const PostdataNews = (Id) =>
  BaseApi.get("News/NewsDocument" + 22);

const GetDistrictSchool = (id) =>
  BaseApi.get("School/GetDistrictSchool/"+id);

const SchoolUpdates = (Uentityid, blockId, Schooltype, Code, name, email, password, headname, mobileNo, totalStudent, TotalTeachingStaff, TotalTrainingSeat) =>
  BaseApi.post(`School/SchoolUpdatenew?Uentityid=${Uentityid}&blockId=${blockId}&schooltype=${Schooltype}&Code=${Code}&name=${name}&email=${email}&password=${password}&headname=${headname}&mobileNo=${mobileNo}&totalStudent=${totalStudent}
&TotalTeachingStaff=${TotalTeachingStaff}&TotalTrainingSeat=${TotalTrainingSeat}`);


const GetDistrictSchoolList = (id) =>
  BaseApi.get("School/GetDistrictSchool/" + id);

  const GetDistrictSchooldiet = (id) =>
  BaseApi.get("School/GetDistrictSchooldiet/" + id);

const GetSchoolDetail = (Uentityid) =>
  BaseApi.get(`School/SchoolDetailnew/${Uentityid}`);

const LocationSchool = (id, RollNO) =>
  BaseApi.get("College/Schoollist" + `${id}/${RollNO}`);
const StudentDetails = (uentityId) =>
  BaseApi.get("College/StudentDetails/" + uentityId);
const SchoolRequest = (uentityId) =>
  BaseApi.get("School/SchoolRequest/" + uentityId);
const Deletechoice = (uentityId) =>
  BaseApi.post("College/Deletechoice", { uentityId: uentityId });

const PostdataForgetpassward = (userCode) =>
  BaseApi.post("Home/ForgetPassward", { userCode: userCode });

const PostdataChangepassward = (userCode, password, newPassword) =>
  BaseApi.post("Home/ChangePassward", { userCode: userCode, password: password, newPassword: newPassword });


// ----------------School Insert----

const SchoolInsertnew = (blockId, Schooltype, Code, name, email, password, headname, mobileNo, totalStudent, TotalTeachingStaff, TotalTrainingSeat, SchoolCat,districtid) =>
  BaseApi.post(`School/SchoolInsert?blockId=${blockId}&schooltype=${Schooltype}&Code=${Code}&name=${name}&email=${email}&password=${password}&headname=${headname}&mobileNo=${mobileNo}&totalStudent=${totalStudent}
&TotalTeachingStaff=${TotalTeachingStaff}&TotalTrainingSeat=${TotalTrainingSeat}&SchoolCat=${SchoolCat}&District=${districtid}&loginId=${districtid}`);

 
const ChangePassward = (userCode, password, newPassword) =>
  BaseApi.post("Home/ChangePassward", { userCode: userCode, password: password, newPassword: newPassword });
const IsConfirmCollege = (id, isConfirm,) =>
  BaseApi.post("College/IsConfirm", { id: id, isConfirm: isConfirm, });
const ChoicesConfirm = (id, choicesConfirm) =>
  BaseApi.post("College/ChoicesConfirm", { id: id, choicesConfirm: choicesConfirm, });
const StudentSchoolChoice = (uentityId, studentId, priority, schoolId) =>
  BaseApi.post("College/StudentSchoolChoice", { uentityId: uentityId, studentId: studentId, priority: priority, schoolId: schoolId });

const Postdatas = (form) => BaseApi.post("College/StudentInsert", form);

const Newstudentinsert = (code, Name, address, PinCode, fatherName, motherName, genderid, dob, classid, studentTypeid, sessionid, totalmarks, AdharNo, collegeid) =>
  BaseApi.post("College/NewStudentInsert", {
    code: code, Name: Name, address: address, PinCode: PinCode, fatherName: fatherName, motherName: motherName,
    genderid: genderid, dob: dob, classid: classid, studentTypeid: studentTypeid, sessionid: sessionid, totalmarks: totalmarks, AdharNo: AdharNo, collegeid: collegeid
  });

const UpdateStudentdata = (uentityId, studentName, pinCode, fatherName, motherName, genderid, mobileNo, emailId, adharNo, rollno, classid, studentType ) =>

  BaseApi.post("College/StudentUpdate", {
    uentityId: uentityId, Name: studentName, PinCode: pinCode, fatherName: fatherName, motherName: motherName,
     genderid: genderid, mobileNo: mobileNo, emailId: emailId, adharNo: adharNo, rollno: rollno, classid: classid, studentType: studentType  
  });


const GetNotification = (id) =>
  BaseApi.get(`Admin/MasterDetail` + id);

const GetDistrict = () =>
  BaseApi.get(`Location/DistrictList`);

const GetStudentDetails = (Id) =>
  BaseApi.get(`College/StudentDetails/${Id}`);

export default {
  // JBT
  Dashboard,
  getdataLpgin,
  LoginJBT,
  PostdataForgetpassward,
  PostdataChangepassward,
  Postdatastudentlist,
  PostdataNews,
  Postdatas,
  ChangePassward,
  IsConfirmCollege,
  getdataSingle,
  StudentSchoolChoice,
  ChoicesConfirm,
  StudentDetails,
  SchoolRequest,
  SchoolUpdates,
  Block,
  SchoolInsertnew,
  Totaldata,
  LocationSchool,
  Newstudentinsert,
  Pendingdatastudentlist,
  Deletechoice,
  UpdateStudentdata,
  GetNotification,
  GetDistrict,
  GetReRunstudentlist,
  GetStudentDetails,
  GetSchoolDetail,
  GetDistrictSchool,
  GetDistrictSchoolList,
  GetDistrictSchooldiet
}
