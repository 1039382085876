import React, { useState, useEffect, useContext } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import MasterTypeApi from '../../../API/MasterTypeApi';
import { Provider } from '../../../Context/Appcontext';
import { useParams, useNavigate } from 'react-router-dom'
import SchoolDashboardApi from '../../../API/SchoolDashboardApi';

const validationSchema = Yup.object().shape({

    blockId: Yup.string().required("Block Required!"),
    Schooltype: Yup.string().required("School Type Required!"),
    Code: Yup.string().required("UserName Required!"),
    name: Yup.string().required("SchoolName Required!"),
    email: Yup.string().email().required("Email Required!"),
    headname: Yup.string().required("headname Required!"),
    password: Yup.string().min(5).required("password Required!"),
    mobileNo: Yup.number().min(10).required("mobileNo Required!"),
    totalStudent: Yup.number().required("Total Student Required!"),
    TotalTrainingSeat: Yup.number().required("Total Training Seat Required!"),
    TotalTeachingStaff: Yup.number().required("TotalTeachingStaff Required!"),

});



const NewSchoolInsert = () => {
    const notify = () => toast.success('Sucessfully Inserted!')
    const notify2 = () => toast.error('Please Check Userename and password!')
    const errormsg = (Data) => toast.error(Data
        , {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

    const [BlockData, setBlockData] = useState([]);
    const [MasterTypedata, setMasterTypedata] = useState([]);
    const [Imagefile, setImagefile] = useState('');
    const handeleimage = (e) => {
        setImagefile(e.target.files[0]);
    }

    const context = useContext(Provider);
    const { id } = useParams();
    const navigate = useNavigate();




    useEffect(() => {
        Blocklist();

       console.log('HIIIIIIIIII',BlockData)
    //    console.log('byyyyyyyyy',context.user)
    }, []);
    // mastertype dropdown

    // const Getmastertype = () => {
    //     MasterTypeApi.GetMasterTypedata()
    //         .then((resp) => {
    //             if (resp.ok) {
    //                 let Data = resp.data;
    //                 setMasterTypedata(Data);

    //                 // console.log("Student  List........===>", Data);
    //                 // console.log('hi sunny',context.UserDetail.locationId)
    //             }
    //         });
    // };

    const Blocklist = () => {
        SchoolDashboardApi.Block(context.user)
            .then((resp) => {
                if (resp.ok) {
                    let Data = resp.data;
                    setBlockData(Data);

                    // console.log("Student  List........===>", Data);
                    // console.log('hi sunny',context.UserDetail.locationId)
                }
            });
    };


    //   post data function
    const postData = (values, resetForm) => {

        console.log('sunny',id,values)
        SchoolDashboardApi.SchoolInsertnew(

            values.blockId,
            values.Schooltype,
            values.Code,
            values.name,
            values.email,
            values.password,
            values.headname,
            values.mobileNo,
            values.totalStudent,
            values.TotalTeachingStaff,
            values.TotalTrainingSeat
           

            // console.log("helo",values.permission)
        )

            .then((resp) => {
                // console.log("id",id)

                if (resp.ok) {
                    let Data = resp.data
                    if (Data == "Success") {
                        resetForm();
                        // Getdata();
                        notify();
                        navigate(-1);
                    }
                    else {
                        resetForm();
                        // Getdata();
                        errormsg(Data);

                    }
                }
                else if (resp.status == 409) {

                    notify2();

                }

            })
            .catch((err) => console.log(err));

        // navigate(-1);

    };

    // const postData = async (values, resetForm) => {
    //     let form = new FormData();

    //     form.append('Name', values.name);
    //     form.append('typeid', values.typeid);
    //     form.append('Filename', Imagefile);
    //     form.append('isactive', values.isactive);

    //     await MasterTypeApi.PostMasterTypeDetails(form)
    //         .then((resp) => {
    //             if (resp.ok) {

    //                 setImagefile('');
    //                 let Data = resp.data
    //                 if (Data == "inserted Successfully !"

    //                 ) {
    //                     resetForm();
    //                     setImagefile('');
    //                     setData(null);
    //                     Getdata();
    //                     notify();

    //                 }
    //                 else {
    //                     resetForm();
    //                     notify();

    //                 }
    //             }
    //         })
    //         .catch((err) => console.log(err));


    // };

    // ----------------------------update data------------------------------------

    // const Updatedata = async (values, resetForm) => {
    //     let form = new FormData();
    //     form.append('id', values.id);

    //     form.append('Name', values.name);
    //     form.append('typeid', values.typeid);
    //     form.append('Filename', Imagefile);
    //     form.append('isactive', values.isactive);

    //     await MasterTypeApi.UpdateMasterTypeDetails(form)
    //         .then((resp) => {
    //             if (resp.ok) {

    //                 setImagefile('');
    //                 let Data = resp.data
    //                 if (Data == "updated Successfully !"

    //                 ) {
    //                     resetForm();
    //                     setImagefile('');
    //                     setData(null);
    //                     Getdata();
    //                     notify();

    //                 }
    //                 else {
    //                     resetForm();
    //                     notify();

    //                 }
    //             }
    //         })
    //         .catch((err) => console.log(err));


    // };







    return (
        <>

            {/* <div className="panel panel-default paper-shadow" data-z="0.5"> */}

            <div className="card border-warning mb-3">
                <div className="card-header bg-info text-white">Change Password</div>
                <div className="card-title text-danger ml-3 mt-3">All Fields Are Mandatory *</div>
                <div className="card-body ">
                    <Formik
                        initialValues={{
                            blockId: '',
                            Schooltype: '',
                            Code: '',
                            name: '',
                            email: '',
                            password: '',
                            headname: '',
                            mobileNo: '',
                            totalStudent: '',
                            TotalTeachingStaff: '',
                            TotalTrainingSeat: '',


                            // name: isPost ? '' : editData[0].name,
                            // typeid: isPost ? '' : editData[0].typeid,
                            // image: isPost ? '' : editData[0].image,
                            // isactive: isPost ? 1 : editData[0].isactive,






                        }}

                        onSubmit={(values, { resetForm }) => {
                            // same shape as initial values
                            // isPost ? postData(values, resetForm) : putData(values, resetForm);
                            postData(values, resetForm);
                        }}
                        validationSchema={validationSchema}

                    >
                        {({ errors, touched, values, handleChange, handleBlur }) => (
                            <Form>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="blockId">Block</label>
                                            <select
                                                name='blockId'
                                                id='blockId'
                                                className='form-control input-default'
                                                onBlur={handleBlur}
                                                value={values.blockId}
                                                onChange={handleChange}
                                            >
                                                <option value={''}> Select Block</option>
                                                {BlockData.map((item) => (
                                                    <option value={item.id}>{item.name}</option>
                                                ))}
                                            </select>
                                            {errors.blockId && touched.blockId ? (
                                                <div style={{ color: "red" }}>{errors.blockId}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="Schooltype">School Type</label>
                                            <select
                                                name='Schooltype'
                                                id='Schooltype'
                                                className='form-control input-default'
                                                onBlur={handleBlur}
                                                value={values.Schooltype}
                                                onChange={handleChange}
                                            >
                                                <option value={''}> School Type</option>
                                               
                                                    <option value={1}>Primary</option>
                                                    <option value={2}>Middle</option>
                                            </select>
                                            {errors.Schooltype && touched.Schooltype ? (
                                                <div style={{ color: "red" }}>{errors.Schooltype}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="Code">Unique School Code </label>
                                            <input type="text"
                                                className="form-control"
                                                placeholder="Enter School Code"
                                                name="Code"
                                                id="Code"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.Code}
                                            />
                                            {errors.Code && touched.Code ? (
                                                <div style={{ color: "red" }}>{errors.Code}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="name">School Name</label>
                                            <input type="text"
                                                className="form-control"
                                                placeholder="Enter name"
                                                name="name"
                                                id="name"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.name}
                                            />
                                            {errors.name && touched.name ? (
                                                <div style={{ color: "red" }}>{errors.name}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="email">School Email</label>
                                            <input type="text"
                                                className="form-control"
                                                placeholder="Enter email"
                                                name="email"
                                                id="email"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.email}
                                            />
                                            {errors.email && touched.email ? (
                                                <div style={{ color: "red" }}>{errors.email}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="password">School Password</label>
                                            <input type="text"
                                                className="form-control"
                                                placeholder="Enter password"
                                                name="password"
                                                id="password"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.password}
                                            />
                                            {errors.password && touched.password ? (
                                                <div style={{ color: "red" }}>{errors.password}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="headname">School HeadName</label>
                                            <input type="text"
                                                className="form-control"
                                                placeholder="Enter headname"
                                                name="headname"
                                                id="headname"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.headname}
                                            />
                                            {errors.headname && touched.headname ? (
                                                <div style={{ color: "red" }}>{errors.headname}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="mobileNo">School Mobile No</label>
                                            <input type="number"
                                                className="form-control"
                                                placeholder="Enter mobileNo"
                                                name="mobileNo"
                                                id="mobileNo"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.mobileNo}
                                            />
                                            {errors.mobileNo && touched.mobileNo ? (
                                                <div style={{ color: "red" }}>{errors.mobileNo}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="totalStudent">Total Student</label>
                                            <input type="number"
                                                className="form-control"
                                                placeholder="Enter totalStudent"
                                                name="totalStudent"
                                                id="totalStudent"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.totalStudent}
                                            />
                                            {errors.totalStudent && touched.totalStudent ? (
                                                <div style={{ color: "red" }}>{errors.totalStudent}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="TotalTeachingStaff">Total Teaching Staff</label>
                                            <input type="number"
                                                className="form-control"
                                                placeholder="Enter TotalTeachingStaff"
                                                name="TotalTeachingStaff"
                                                id="TotalTeachingStaff"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.TotalTeachingStaff}
                                            />
                                            {errors.TotalTeachingStaff && touched.TotalTeachingStaff ? (
                                                <div style={{ color: "red" }}>{errors.TotalTeachingStaff}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="TotalTrainingSeat"> Total Training Seat</label>
                                            <input type="number"
                                                className="form-control"
                                                placeholder="Enter TotalTrainingSeat"
                                                name="TotalTrainingSeat"
                                                id="TotalTrainingSeat"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.TotalTrainingSeat}
                                            />
                                            {errors.TotalTrainingSeat && touched.TotalTrainingSeat ? (
                                                <div style={{ color: "red" }}>{errors.TotalTrainingSeat}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                   







                                </div>


                                <div className="row">


                                    {/* <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="Address">Address</label>
                                                <input type="text"
                                                    className="form-control"
                                                    placeholder="Enter Address"
                                                    name="address"
                                                    id="address"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.address}
                                                />
                                                {errors.address && touched.address ? (
                                                    <div style={{ color: "red" }}>{errors.address}</div>
                                                ) : null}
                                            </div>
                                        </div> */}

                                </div>
                                {/* <button type="submit" className="btn btn-primary">Submit</button> */}
                                <button type="submit" className="btn btn-primary "> Submit
                                    {/* {isPost ? "submit": "update".editData} */}


                                </button>



                            </Form>
                        )}
                    </Formik>
                </div>

            </div>






        </>
    );
}

export default NewSchoolInsert;
