import React, { useState, useEffect, useContext } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import MasterTypeApi from '../../../API/MasterTypeApi';
import { Provider } from '../../../Context/Appcontext';
import { useParams, useNavigate } from 'react-router-dom'

const validationSchema = Yup.object().shape({


    UserCode: Yup.string().required("UserName Required!"),
    OldPassword: Yup.string().required("OldPassword Required!"),
    NewPassword: Yup.string().min(5).required("NewPassword Required!"),

});



const SchoolPaswordChange = () => {
    const notify = () => toast.success('Sucessfully Changed!')
    const notify2 = () => toast.error('Please Check Userename and password!')
    const errormsg = (Data) => toast.error(Data
        , {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

    const [Data, setData] = useState(null);
    const [MasterTypedata, setMasterTypedata] = useState([]);
    const [Imagefile, setImagefile] = useState('');
    const handeleimage = (e) => {
        setImagefile(e.target.files[0]);
    }

    const context = useContext(Provider);
    const { id } = useParams();
    const navigate = useNavigate();




    useEffect(() => {
        Getdata();

        // console.log('HIIIIIIIIII',context.UserDetail)
    }, []);
    // mastertype dropdown

    // const Getmastertype = () => {
    //     MasterTypeApi.GetMasterTypedata()
    //         .then((resp) => {
    //             if (resp.ok) {
    //                 let Data = resp.data;
    //                 setMasterTypedata(Data);

    //                 // console.log("Student  List........===>", Data);
    //                 // console.log('hi sunny',context.UserDetail.locationId)
    //             }
    //         });
    // };

    const Getdata = () => {
        MasterTypeApi.GetStaffDetails()
            .then((resp) => {
                if (resp.ok) {
                    let Data = resp.data;
                    setData(Data);

                    // console.log("Student  List........===>", Data);
                    // console.log('hi sunny',context.UserDetail.locationId)
                }
            });
    };


    //   post data function
    const postData = (values, resetForm) => {

        console.log('test',id,values)
        MasterTypeApi.SchoolPasswordChange(

            context.user,
            values.UserCode,
            values.OldPassword,
            values.NewPassword

            // console.log("helo",values.permission)
        )

            .then((resp) => {
                // console.log("id",id)

                if (resp.ok) {
                    let Data = resp.data
                    if (Data == "Successfully Changed") {
                        resetForm();
                        // Getdata();
                        notify();

                    }
                    else {
                        resetForm();
                        // Getdata();
                        errormsg(Data);

                    }
                }
                else if (resp.status == 409) {

                    notify2();

                }

            })
            .catch((err) => console.log(err));

        navigate(-1);

    };

    // const postData = async (values, resetForm) => {
    //     let form = new FormData();

    //     form.append('Name', values.name);
    //     form.append('typeid', values.typeid);
    //     form.append('Filename', Imagefile);
    //     form.append('isactive', values.isactive);

    //     await MasterTypeApi.PostMasterTypeDetails(form)
    //         .then((resp) => {
    //             if (resp.ok) {

    //                 setImagefile('');
    //                 let Data = resp.data
    //                 if (Data == "inserted Successfully !"

    //                 ) {
    //                     resetForm();
    //                     setImagefile('');
    //                     setData(null);
    //                     Getdata();
    //                     notify();

    //                 }
    //                 else {
    //                     resetForm();
    //                     notify();

    //                 }
    //             }
    //         })
    //         .catch((err) => console.log(err));


    // };

    // ----------------------------update data------------------------------------

    // const Updatedata = async (values, resetForm) => {
    //     let form = new FormData();
    //     form.append('id', values.id);

    //     form.append('Name', values.name);
    //     form.append('typeid', values.typeid);
    //     form.append('Filename', Imagefile);
    //     form.append('isactive', values.isactive);

    //     await MasterTypeApi.UpdateMasterTypeDetails(form)
    //         .then((resp) => {
    //             if (resp.ok) {

    //                 setImagefile('');
    //                 let Data = resp.data
    //                 if (Data == "updated Successfully !"

    //                 ) {
    //                     resetForm();
    //                     setImagefile('');
    //                     setData(null);
    //                     Getdata();
    //                     notify();

    //                 }
    //                 else {
    //                     resetForm();
    //                     notify();

    //                 }
    //             }
    //         })
    //         .catch((err) => console.log(err));


    // };







    return (
        <>

            {/* <div className="panel panel-default paper-shadow" data-z="0.5"> */}

            <div className="card border-warning mb-3">
                <div className="card-header bg-info text-white">Change Password</div>
                <div className="card-title text-danger ml-3 mt-3">All Fields Are Mandatory *</div>
                <div className="card-body ">
                    <Formik
                        initialValues={{
                            UserCode: '',
                            OldPassword: '',
                            NewPassword: ''


                            // name: isPost ? '' : editData[0].name,
                            // typeid: isPost ? '' : editData[0].typeid,
                            // image: isPost ? '' : editData[0].image,
                            // isactive: isPost ? 1 : editData[0].isactive,






                        }}

                        onSubmit={(values, { resetForm }) => {
                            // same shape as initial values
                            // isPost ? postData(values, resetForm) : putData(values, resetForm);
                            postData(values, resetForm);
                        }}
                        validationSchema={validationSchema}

                    >
                        {({ errors, touched, values, handleChange, handleBlur }) => (
                            <Form>
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="form-group">
                                            <label htmlFor="UserCode">New UserName</label>
                                            <input type="text"
                                                className="form-control"
                                                placeholder="Enter New UserName"
                                                name="UserCode"
                                                id="UserCode"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.UserCode}
                                            />
                                            {errors.UserCode && touched.UserCode ? (
                                                <div style={{ color: "red" }}>{errors.UserCode}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="form-group">
                                            <label htmlFor="Password">Old Password</label>
                                            <input type="text"
                                                className="form-control"
                                                placeholder="Enter Old Password"
                                                name="OldPassword"
                                                id="OldPassword"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.OldPassword}
                                            />
                                            {errors.OldPassword && touched.OldPassword ? (
                                                <div style={{ color: "red" }}>{errors.OldPassword}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="form-group">
                                            <label htmlFor="NewPassword">New Password</label>
                                            <input type="text"
                                                className="form-control"
                                                placeholder="Enter NewPassword"
                                                name="NewPassword"
                                                id="NewPassword"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.NewPassword}
                                            />
                                            {errors.NewPassword && touched.NewPassword ? (
                                                <div style={{ color: "red" }}>{errors.NewPassword}</div>
                                            ) : null}
                                        </div>
                                    </div>







                                </div>


                                <div className="row">


                                    {/* <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="Address">Address</label>
                                                <input type="text"
                                                    className="form-control"
                                                    placeholder="Enter Address"
                                                    name="address"
                                                    id="address"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.address}
                                                />
                                                {errors.address && touched.address ? (
                                                    <div style={{ color: "red" }}>{errors.address}</div>
                                                ) : null}
                                            </div>
                                        </div> */}

                                </div>
                                {/* <button type="submit" className="btn btn-primary">Submit</button> */}
                                <button type="submit" className="btn btn-primary "> Submit
                                    {/* {isPost ? "submit": "update".editData} */}


                                </button>



                            </Form>
                        )}
                    </Formik>
                </div>

            </div>






        </>
    );
}

export default SchoolPaswordChange;
