import React, { useState, useEffect, useContext } from 'react';
import MasterTypeApi from '../../../API/MasterTypeApi';
import { Link } from 'react-router-dom'

const ReRunDistrict = () => {
    const [Data, setData] = useState(null);


    useEffect(() => {
        Getdata();
    }, []);


    const Getdata = () => {
        MasterTypeApi.GetDistrict()
            .then((resp) => {
                if (resp.ok) {
                    let Data = resp.data;
                    setData(Data);

                    // console.log("Student  List........===>", Data);
                    // console.log('hi sunny',context.UserDetail.locationId)
                }
            });
    };


    return (
        <>
            {Data != null ? Data.length > 0 ? (
                <div className="table-responsive">
                    <table className="table table-hover table-outline  mb-0 ">
                        <thead >
                            <tr>
                                <th className="text-center">Sr No</th>
                                <th className="text-center">Name</th>
                                <th className="text-center">Action</th>



                                {/* <th className="text-center">Attendence </th> */}

                            </tr>
                        </thead>
                        <tbody>
                            {Data.map((data, i) => (
                                <tr key={data.id}>
                                    <td className="text-center">{i + 1}</td>
                                    <td className="text-center">{data.name}</td>
                                    <td className="text-center">
                                        <Link to={`/admin/DistrictCollagelist/${data.id}`}>  <button type="button" className="btn btn-success">Collage Details</button></Link>
                                    </td>



                                </tr>
                            ))}

                        </tbody>
                    </table>

                </div>

            ) : <center><h3>School Not Available</h3></center> //<BeatLoader color="blue" loading />
                :

                <div className='d-flex justify-content-center align-items-center'>
                    <button class="btn btn-primary" type="button" disabled>
                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        Loading...
                    </button>
                </div>
            }
        </>
    );
}

export default ReRunDistrict;
