import React, { useState, useEffect, useContext } from 'react'
import { Provider } from '../../../Context/Appcontext';
import { toast } from 'react-toastify';
import DistrictschoolApi from '../../../API/DistrictschoolApi';
import { Link } from 'react-router-dom'
import MasterTypeApi from '../../../API/MasterTypeApi';

import { useParams } from 'react-router-dom'
import LoginApi from '../../../API/LoginApi';
const notify = () => toast.success('Orientation Completed!')
const notify2 = () => toast.error('Orientation Already Completed!')


const StudentlistChoice = () => {
    const context = useContext(Provider);
    const [StudentListAll, setStudentListAl] = useState(null);
    const [StudentList, setStudentList] = useState();
    const [inputRollNo, setInputRollNo] = useState("");


    const { id } = useParams();
    const { typeid } = useParams();

    useEffect(() => {
        GetStudentList();

    }, []);


    const GetStudentList = () => {

        // console.log("Stud userId........", props.userDetail);
        MasterTypeApi.GetstudentlistChoice(context.UserDetail.locationId, id,typeid).then(
            (resp) => {
                if (resp.ok) {
                    let Data = resp.data;
                    setStudentList(Data)
                    setStudentListAl(Data)
                    console.log('check student list', resp.data)
                }
            }
        );
    };


   
    const FilterByRollNo = () => {
        const Filter = StudentListAll.filter(x => x.rollNo == inputRollNo);

        setStudentList(Filter);
    }
    //   const handleClick = value => console.log(value);

    return (
        <>

            <div className="page-section">

                <h1 className="text-display-1">Student List</h1>

            </div>
            <div className="row">
                {/* <label htmlFor="name">School Name</label> */}

                <div className="col-md-4">

                    <input type="text" className="form-control" placeholder="Enter Roll Number"

                        onChange={(v) => setInputRollNo(v.target.value)} value={inputRollNo} />
                </div>
                <button className="btn btn-primary" onClick={() => FilterByRollNo()}>Find Roll Number</button>
            </div>

            <div className="page-section">

                {/* <h1 className="text-display-1">Student List</h1> */}

            </div>
            {StudentList && StudentList.length > 0 ? (
                <table className="table table-hover table-outline  mb-0 ">
                    <thead >
                        <tr>
                            <th className="text-center">Sr.No</th>
                            <th className="text-center">Roll No</th>
                            <th className="text-center">Student Name</th>
                            <th className="text-center">Mobile Number</th>
                            <th className="text-center">E-Mail</th>
                            <th className="text-center">Collage Name</th>
                            {/* <th className="text-center">Orientation Status</th> */}
                      


                        </tr>
                    </thead>

                    <tbody>
                        {StudentList.map((data, i) => (
                            <tr key={data.uentityId}>
                                <td className="text-center">{i + 1}</td>
                                <td className="text-center">{data.rollNo}</td>
                                <td className="text-center">{data.studentName}</td>
                                <td className="text-center">{data.mobileNo}</td>
                                <td className="text-center">{data.emailId}</td>
                                <td className="text-center">{data.collegeName}</td>
                                {/* <td className="text-center">{data.isOrientation}</td> */}
                                {/* <td className="text-center">
                                    {data.isOrientation
                                        ? 'Orietation Done'
                                        : 'Orietation Not Done'}
                                </td> */}





                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : <center><h3>Student Not Available</h3></center>
            }




        </>


    );
}

export default StudentlistChoice;
