// import React, { Component } from 'react'
import React, { useState, useEffect, useContext } from 'react';

import { Link } from "react-router-dom"
// import {
//   CCard,
//   CCardBody,
// } from '@coreui/react';
import Card from 'react-bootstrap/Card';
import MasterTypeApi from '../../../API/MasterTypeApi';
// import { connect } from 'react-redux';



export default function StaffDashboard() {

  const [Data, setData] = useState(null);

  useEffect(() => {
    Getdata();
  }, []);


  const Getdata = () => {
    MasterTypeApi.GetSchoolNotification(1002)
      .then((resp) => {
        if (resp.ok) {
          let Data = resp.data;
          setData(Data);

          console.log("notification  List........===>", Data);
          // console.log('hi sunny',context.UserDetail.locationId)
        }
      });
  };

  // GetStudentList = () => {
  //   SchoolDashboardApi.Dashboard(this.props.userDetail).then(
  //     (resp) => {
  //       if (resp.ok) {
  //         let Data = resp.data;
  //         // console.log("collage D........", Data);
  //         this.setState({ collage: Data });
  //       }
  //     }
  //   );
  // };

  // GetAdminCount = () => {
  //   ExportApis.AdminData().then(
  //     (resp) => {
  //       if (resp.ok) {
  //         let Data = resp.data;
  //           //console.log("Total collage D........", Data);
  //         // this.setState({ total: Data });
  //       }
  //     }
  //   );
  // };



  // console.log("userDetail", userDetail)

  // var token = localStorage.getItem("user");
  //  var decoded = jwt_decode(token);
  //  const uEntityId =decoded.unique_name

  // console.log("decoded",  decoded.unique_name);

  return (
    <>
      {/* <div>


        <h1>----Notification----</h1>
      </div> */}
      {/* <div>



        {Data != null && Data.length > 0 && Data.map((data, i) => (
          <div class="row" style={{ marginBottom: 15 }}>
            <div class="col-md-10">
              <h3>{data.name}</h3>
            </div>
            <div class="col-3">
              <div className='align-items-center'>
                <img
                  src={
                    data.image
                  }
                  className='rounded-lg mr-2'
                  width='80'
                  height='50'
                  alt=''
                />
              </div>
            </div>
          </div>
        ))}
      </div> */}
      {/* -----------design Test---------------- */}

      <div className="item col-xs-12 col-lg-12">
        <div className="panel panel-default paper-shadow" data-z="0.5">
          <div className="panel-heading">
            <div className="media v-middle">
              <div className="media-body">
                <h4 className="text-headline margin-none">Notifcation</h4>
                <p className="text-subhead text-light">Latest News</p>
              </div>
              <div className="media-right">
                {/* <a
                  className="btn btn-white btn-flat"
                 
                >
                  Image 
                </a> */}
              </div>
            </div>
          </div>
          <div className="table-responsive">
            {Data != null && Data.length > 0 && Data.map((data, i) => (
              <table className="table text-subhead v-middle">
                <tbody>
                  <tr>
                    <td className="width-100 text-caption">
                      <div className="label label-grey-200 label-xs"><p>{data.name}</p></div>
                    
                    </td>
                    {/* <td className="width-80 text-center">{data.name}</td> */}
                    {/* <td className="width-80 text-center">
                    <a href="#">#9936</a>
                  </td> */}
                    <td className="width-50 "><div className='align-items-center'>
                      <a
                        href={data.image}
                      
                      >
                        {/* Details-- */}
                        <p className="text-subhead text-light">Details <i className="fa fa-download" /> </p>

                       
                    
                      {/* <img
                        src={
                          data.image
                        }
                        className='rounded-lg mr-2'
                        width='80'
                        height='50'
                        alt=''
                      /> */}
                        </a>
                    </div></td>

                  </tr>

                </tbody>
              </table>
            ))}
          </div>
        </div>
      </div>


    </>
  )
}

