import React, { useState, useEffect, useContext } from 'react'
import AttendenceApi from '../../../API/AttendenceApi';
import SchoolDashboardApi from '../../../API/SchoolDashboardApi';
import { Provider } from '../../../Context/Appcontext';
import { toast } from 'react-toastify';

export default function SchoolStudentlist({ ...props }) {


    const context = useContext(Provider);

    const notify = () => toast.success('Sucessfully Inserted!', { autoClose: 1000 })
    const errormsg = (msg = "Roll No Already Exists !") => toast.error(msg, { autoClose: 1500 })

    const [StudentList, setStudentList] = useState([]);
    const [StudentAttendence, setStudentAttendence] = useState([]);
    const [attDate, setattDate] = useState();
    const [Attendence, setAttendence] = useState();
    const [MarkAtt, setMarkAtt] = useState(false);
    const [today, setToday] = useState();

    var current = new Date();
    var dd = current.getDate();
    var mm = current.getMonth() + 1; //January is 0!
    var yyyy = current.getFullYear();

    if (dd < 10) {
        dd = '0' + dd;
    }

    if (mm < 10) {
        mm = '0' + mm;
    }

    current = yyyy + '-' + mm + '-' + dd;



    useEffect(() => {
        handleStudentdata();

        // setToday(date)
    }, [1000]);

    const handleStudentdata = async () => {
        const resp = await context.GetSchoolStudentList(context.user, 1);
        if (resp) {
            setStudentList(resp);
        }
    };

    const handleattendance = (attDatea) => {
        AttendenceApi.GetAttendence(context.user, attDatea, 1).then((resp) => {
            if (resp.ok) {
                let Data = resp.data;
                setAttendence(Data);
                //console.log("Atten........===>", Data);
                setMarkAtt(true)
            }
        });
    };





    const handleChange = (e) => {
        var index = StudentAttendence.findIndex(o => o.studentid === e.target.name);

        if (index !== -1) StudentAttendence.splice(index, 1);

        const newData = [...StudentAttendence, { studentid: e.target.name, attendence: e.target.value }]
        setStudentAttendence(newData)
        console.log("aatt", newData)

    }
    const handledate = async (e) => {
        const newdate = await e.target.value
        await setattDate(newdate)
        handleattendance(newdate)
    }



    const handleSubmit =  async (evt) => {
        evt.preventDefault()
        await StudentAttendence.forEach((element) => {
           AttendenceApi.StudentAttendencee(
                element.studentid, context.user, element.attendence, current, 1
            )
                .then((resp) => {
                    if (resp.ok) {
                        let Data = resp.data;
                        if (Data == "Marked Successfully !") {
                            notify()

                        }
                        else {
                            errormsg()
                        }


                    } else {
                        if (resp.status == 409) {

                            errormsg(resp.data)
                        }
                    }
                })
                .catch((err) => console.log(err));
        });
    }


    return (
        <>
            <div className="page-section">
                <h1 className="text-display-1">Student List</h1>
            </div>


            <form onSubmit={handleSubmit} >
                {StudentList && StudentList.length > 0 ? (
                    <div className="panel panel-default paper-shadow" data-z="0.5">
                        <div className="panel-heading">
                            <div className=" form-group daterangepicker-report" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <div className="form-control max-width-300">
                                    <i className="fa fa-calendar fa-fw" />
                                    <span><strong>Student List (Today : {current})</strong></span>
                                    <b className="caret" />
                                </div>
                                {/* <div className=" max-width-300">
                                    <input type="date" className="form-control" onChange={handledate}
                                        required
                                        min={current}
                                        max={current} />
                                </div> */}
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-hover table-outline  mb-0 ">
                                <thead >
                                    <tr>
                                        <th className="text-center">Sr.No</th>
                                        <th className="text-center">StudentCode</th>
                                        <th className="text-center">Roll No</th>
                                        <th className="text-center">Student Name</th>
                                        <th className="text-center">Father Name</th>
                                        <th className="text-center">Collage Name</th>
                                        <th className="text-center">Mark </th>
                                        <th className="text-center">   </th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {StudentList.map((data, i) => (
                                        <tr key={data.uentityId}>

                                            <td className="text-center">{i + 1}</td>
                                            <td className="text-center">{data.studentid}</td>
                                            <td className="text-center">{data.rollNo}</td>
                                            <td className="text-center"><strong>{data.studentName}</strong></td>
                                            <td className="text-center">{data.fatherName}</td>
                                            <td className="text-center">{data.collegeName}({data.collegeLocation})      </td>



                                            <>



                                                <td className="text-center ">
                                                    <input
                                                        type="radio"

                                                        value="Present"
                                                        name={data.studentid}
                                                        onChange={handleChange}
                                                        checked={data.attendence == "Present" ? true : null}
                                                        disabled={data.attendence != "" ? true : false}
                                                           // required
                                                    />
                                                     <strong style={{ color: "green" }}>Present</strong>
                                                </td>
                                                <td className="text-center ">
                                                    <input

                                                        type="radio"
                                                        value="Absent"
                                                        name={data.studentid}
                                                        onChange={handleChange}
                                                        checked={data.attendence == "Absent" ? true : null}
                                                        disabled={data.attendence != "" ? true : false}
                                                    // required
                                                    /> <strong style={{ color: "red" }}>Absent</strong>

                                                </td>


                                            </>



                                        </tr>
                                    ))}

                                </tbody>
                            </table>

                        </div>
                        <div className="panel-footer">
                            <button type='submit' className="btn btn-primary mr-5" style={{ float: 'right', }} >Submit</button>
                            <strong className='ml-5' style={{ color: "red" }}>Once Submited It Will Not Changed *</strong>
                        </div>
                    </div>
                ) :
                    <center><h3>Student Not Available</h3></center> //<BeatLoader color="blue" loading />
                }

            </form>


        </>
    )
}
