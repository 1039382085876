import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';  
import { AppContext } from './Context/Appcontext';
import { BrowserRouter, useNavigate } from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';

const MainApp = () => {
  const navigate = useNavigate();

  return (
    <AppContext navigate={navigate}>
      <App />
      {/* <ToastContainer /> */}
    </AppContext>
  )
}
  
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
    <MainApp />
    </BrowserRouter>

  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
