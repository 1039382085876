import React from 'react';

const AdminDashboard = () => {
    return (
        <>

            {/* <CCard className="bg-info">
          <CCardBody className="text-center">
            <h3>Welcome to</h3>
            <h4>Sip</h4>
            <h6>helo</h6>
          </CCardBody>
        </CCard> */}

            <div className="st-pusher" id="content">
                <div className="st-content">
                    {/* sidebar effects INSIDE of st-pusher: */}
                    {/* st-effect-3, st-effect-6, st-effect-7, st-effect-8, st-effect-14 */}
                    {/* this is the wrapper for the content */}

                    {/* extra div for emulating position:fixed of the menu */}
                    <div className="st-content-inner padding-none">
                        <div className="container-fluid">
                            <div className="page-section">
                                <h1 className="text-display-1">Admin Details</h1>
                            </div>
                            <div className="row" data-toggle="isotope">
                                <div className="item col-xs-12 col-sm-6 col-lg-4">
                                    <div className="panel panel-default paper-shadow" data-z="0.5">
                                        <div className="cover overlay cover-image-full hover">
                                            <span className="img icon-block height-150 bg-primary" />
                                            <a
                                                href="app-instructor-course-edit-course.html"
                                                className="padding-none overlay overlay-full icon-block bg-primary"
                                            >
                                                <span className="v-center">
                                                    <i className="fa fa-css3" />
                                                </span>
                                            </a>
                                            <a
                                                href="app-instructor-course-edit-course.html"
                                                className="overlay overlay-full overlay-hover overlay-bg-white"
                                            >
                                                <span className="v-center">
                                                    <span className="btn btn-circle btn-primary btn-lg">
                                                        <i className="fa fa-graduation-cap" />
                                                    </span>
                                                </span>
                                            </a>
                                        </div>
                                        <div className="panel-body">
                                            <h4 className="text-headline margin-v-0-10">
                                                <a href="app-instructor-course-edit-course.html">
                                                    Awesome CSS with LESS Processing
                                                </a>
                                            </h4>
                                        </div>
                                        <hr className="margin-none" />
                                        <div className="panel-body">
                                            <a
                                                className="btn btn-white btn-flat paper-shadow relative"
                                                data-z={0}
                                                data-hover-z={1}
                                                data-animated=""
                                                href="app-instructor-course-edit-course.html"
                                            >
                                                <i className="fa fa-fw fa-pencil" /> Edit course
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="item col-xs-12 col-sm-6 col-lg-4">
                                    <div className="panel panel-default paper-shadow" data-z="0.5">
                                        <div className="cover overlay cover-image-full hover">
                                            <span className="img icon-block height-150 bg-lightred" />
                                            <a
                                                href="app-instructor-course-edit-course.html"
                                                className="padding-none overlay overlay-full icon-block bg-lightred"
                                            >
                                                <span className="v-center">
                                                    <i className="fa fa-windows" />
                                                </span>
                                            </a>
                                            <a
                                                href="app-instructor-course-edit-course.html"
                                                className="overlay overlay-full overlay-hover overlay-bg-white"
                                            >
                                                <span className="v-center">
                                                    <span className="btn btn-circle btn-red-500 btn-lg">
                                                        <i className="fa fa-graduation-cap" />
                                                    </span>
                                                </span>
                                            </a>
                                        </div>
                                        <div className="panel-body">
                                            <h4 className="text-headline margin-v-0-10">
                                                <a href="app-instructor-course-edit-course.html">
                                                    Vagrant Portable Environments
                                                </a>
                                            </h4>
                                        </div>
                                        <hr className="margin-none" />
                                        <div className="panel-body">
                                            <a
                                                className="btn btn-white btn-flat paper-shadow relative"
                                                data-z={0}
                                                data-hover-z={1}
                                                data-animated=""
                                                href="app-instructor-course-edit-course.html"
                                            >
                                                <i className="fa fa-fw fa-pencil" /> Edit course
                                            </a>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                    {/* /st-content-inner */}

                    {/* /st-content */}
                </div>


                {/* <div className="row" >
          <div className="col-md-4 " >
            <CCard >
              <CCardBody className="text-center" >
                <h5>Total Student</h5>
                <Link to={{ pathname: '/StudentList', }} className='btn btn-success shadow btn-xs sharp mr-1'>
                  <strong style={{ fontSize: "20px", fontStyle: "bold" }} >10</strong>
                </Link>
              </CCardBody>
            </CCard>
          </div>
         
          
       
        </div> */}
            </div>

        </>
    );
}

export default AdminDashboard;
